import Cookie from 'js-cookie';
import {inspectletDataAttributes} from '../form/inspectletDataAttributes';
import {lookupCenter} from '../form/lookupCenter';
import {displayAvailableAppointments} from '../form/displayAvailableAppointments';
import {confirmationPageInit, confirmationPageCheckboxClick, confirmationPageTextClick} from '../form/osConfirmationPage';
import {initiateSFInfoPartial, saveLeadInfo, retrieveAppointments, saveLeadInfoPartial, saveUpdateLeadInfoAndRetrieveInfo} from '../form/initiateSFInfo';
import {defaultMonthCalendar, twoMonthCalendar} from "../form/calendarVersion";
import {getTimeZoneOffsets, getActualTime, printActualTime} from '../form/time';
import {submitForm} from '../form/submitForm';
import * as ccForms from '../form/formFunctions';
import {w1_step3, initiateStep3MonthCal} from '../form/w1_step3';
import {w1_step3_2month,initiateStep3TwoMonthCal} from '../form/w1_step3_2month';
import moment from 'moment';
import {setOathToken} from '../form/salesforceOath';
import {is_w1, is_w2} from '../global/functions';
import {showWaitingIcon,hideWaitingIcon} from '../form/w1_helper_functions';
import jstz from 'jstz';
import {initMenu,getSuggestions} from '../form/addressVerification';
import {saveCampaign} from '../form/formFunctions';

 
export default {
  init() {
    let is__w1 = is_w1();
    let is__w2 = is_w2();

    let callCenterNumber = $('.trans_id').first().text() || '888-651-9950';
    let zip;
    const validStates = ['AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY', 'AE', 'AA', 'AP', 'al', 'ak', 'as', 'az', 'ar', 'ca', 'co', 'ct', 'de', 'dc', 'fm', 'fl', 'ga', 'gu', 'hi', 'id', 'il', 'in', 'ia', 'ks', 'ky', 'la', 'me', 'mh', 'md', 'ma', 'mi', 'mn', 'ms', 'mo', 'mt', 'ne', 'nv', 'nh', 'nj', 'nm', 'ny', 'nc', 'nd', 'mp', 'oh', 'ok', 'or', 'pw', 'pa', 'pr', 'ri', 'sc', 'sd', 'tn', 'tx', 'ut', 'vt', 'vi', 'va', 'wa', 'wv', 'wi', 'wy', 'ae', 'aa', 'ap', 'Al', 'Ak', 'As', 'Az', 'Ar', 'Ca', 'Co', 'Ct', 'De', 'Dc', 'Fm', 'Fl', 'Ga', 'Gu', 'Hi', 'Id', 'Il', 'In', 'Ia', 'Ks', 'Ky', 'La', 'Me', 'Mh', 'Md', 'Ma', 'Mi', 'Mn', 'Ms', 'Mo', 'Mt', 'Ne', 'Nv', 'Nh', 'Nj', 'Nm', 'Ny', 'Nc', 'Nd', 'Mp', 'Oh', 'Ok', 'Or', 'Pw', 'Pa', 'Pr', 'Ri', 'Sc', 'Sd', 'Tn', 'Tx', 'Ut', 'Vt', 'Vi', 'Va', 'Wa', 'Wv', 'Wi', 'Wy', 'Ae', 'Aa', 'Ap'];
    const locateCenterForm = `
      <div class="form-group bg-light">
        <div class="form-locate-center">
          <div class="row">
            <div class="col-sm-8">
              <h2 class="h5 font-primary">Select A Center</h2>
            </div>
            <div class="col-sm-4">
              <p class="text-muted text-sm-right required-fields-message">*Indicates required field</p>
            </div>
          </div>

          <div class="form-locate-center-lookup">
            <p>Enter your ZIP Code to find your ClearChoice Center.</p>
            <div class="row align-items-center">
              <div class="col-sm-8">
                <div class="form-group has-float-label mb-sm-0">
                  <input id="form-locate-center-address" type="tel" class="form-control form-control-lg" placeholder="*ZIP Code" required>
                  <label for="form-locate-center-address">*ZIP Code</label>
                </div>
              </div>
              <div class="col-sm-4 pl-sm-0">
                <p class="mb-0 text-right"><span class="btn btn-outline-primary px-3 py-2">Find Center</span></p>
              </div>
            </div>
          </div>
        </div>

        <div class="form-locate-center-results d-none">
          <div class="row">
            <div class="col-sm-7">
              <div class="list-group mb-2 mb-sm-0"></div>
            </div>
            <div class="col-sm-5">
              <div id="form-map"></div>
            </div>
          </div>
        </div>
      </div>
    `;


    /**
     * Add/removes [not-]placeholder-shown class for inputs.
     * For getting around IE's lack of support for :placeholder-shown
     */
    let placeholderShown = function() {
      if ( $(this).val() != '' ) {
        $(this).removeClass('placeholder-shown');
        $(this).addClass('not-placeholder-shown');
      } else {
        $(this).addClass('placeholder-shown');
        $(this).removeClass('not-placeholder-shown');
      }
    };

    // fix size of smarty-streets window on partial form mobile
    $(window).bind("load resize",function(e){
      var $setter = $("#mobile-form .field-address input");
      var $setterDesktop = $(".field-address input:not(#mobile-form)");
      $setterDesktop.siblings(".us-autocomplete-pro-menu:not(#mobile-form)").css("max-width",$setterDesktop.width() + 8 + "px");
      $setterDesktop.siblings(".us-autocomplete-pro-menu:not(#mobile-form)").css("width",$setterDesktop.width() + 8 + "px");
      $setter.siblings("#mobile-form .us-autocomplete-pro-menu").css("max-width",$setter.width() + 8 + "px");
      $setter.siblings("#mobile-form .us-autocomplete-pro-menu").css("width",$setter.width() + 8 + "px");
    });
    

    // load the default calendar first 
    $(document).on('gform_page_loaded', function(){
      /*
      var showTwoMonth = Cookie.get('test-2-month');
      if((!showTwoMonth || typeof showTwoMonth == 'undefined')){
        $( ".make-appointment" ).append( defaultMonthCalendar());
        //fix animation on step 3
        var targetNode = document.querySelector('.make-appointment');
        var observer = new MutationObserver(function(){
            if(targetNode.style.display != 'none'){
              $(".breadcrumb.breadcrumb-arrows").get(0).scrollIntoView();
            }
        });
        observer.observe(targetNode, { attributes: true, childList: true });
      }else{
      }
      */
        $( ".make-appointment" ).append( twoMonthCalendar());
        //fix animation on step 3
        var targetNode2 = document.querySelector('.make-appointment');
        var observer2 = new MutationObserver(function(){
            if(targetNode2.style.display != 'none'){
              $(".breadcrumb.breadcrumb-arrows").get(0).scrollIntoView();
            }
        });
        observer2.observe(targetNode2, { attributes: true, childList: true });
    });
   
      /**
       * function for removing the asterisks in the OS form's step2 fields
       */
    let replaceAsterisks = function( ) {
      let label = $(this).find('.gfield_label');
      let input = $(this).find('input');
      let placeholder = input.attr('placeholder');

      // replace first instance only in the label (ignore the <span required>)
      if( label.length > 0 )
        label.html( label.html().replace('*', '') );

      // replace placeholders (if exists)
      if( input.length > 0 && typeof placeholder !== typeof undefined && placeholder !== false )
        input.attr('placeholder', placeholder.replace('*', '') );
    };

    /**
     * After gform is done rendering
     * - non adi - remove required inputs
     * - w1 - move html elements around to match the old html structure
     * - w2 - Float input labels
     * - Show next/submit buttons before previous on mobile
     */
    $(document).bind('gform_post_render', function() {
      //remove required field text and asterisks for non adi form
      if($(".adi-schedule").length == 0){
        var fewQuestions = $('.page-few-questions'); // step1
        var gatherInfo = $('.page-gather-info'); // step2

        fewQuestions.find('.gfield').each( replaceAsterisks );
        gatherInfo.find('.gfield').each( replaceAsterisks );
      }

      // Float labels + Gravity Forms (w2 only)
      if( is__w2 ) {
        $('.gform_wrapper .has-float-label').each(function() {
          $('.gform_wrapper .has-float-label .ginput_container').addClass('has-float-label');
          $('.gform_wrapper .gfield.has-float-label').removeClass('has-float-label');
          $(this).find('.ginput_container').append($(this).find('.gfield_label'));
          $(this).find('.ginput_container input').addClass('form-control form-control-lg');
          $(this).find('.ginput_container select').addClass('form-control form-control-lg');

          // run the placeholder-shown function on each input
          $(this).find('.ginput_container input').each( placeholderShown ); // run once initially on page load
          $(this).find('.ginput_container input').bind( "change click blur input mouseenter", placeholderShown );
        });

      }

      var nonevalue = 'None of the Above';

      // add listeners
      $("input[type='checkbox']").not("[value='" + nonevalue + "']").change( ccForms.checkboxState );
      $("input[value='" + nonevalue + "']").change( ccForms.noneOfTheAboveState );
      // set current checkbox state state on page load
      $("input[type='checkbox']").not("[value='" + nonevalue + "']").each( ccForms.checkboxState );
      $("input[value='" + nonevalue + "']").each( ccForms.noneOfTheAboveState );


      $('.field-phone').find('input').mask("(999) 999-9999"); //set mask for phone number
      $('.field-zipcode').find('input').mask("99999"); // 1st zipcode - step 2a
      $('#form-locate-center-address').mask("99999"); // 2nd zipcode field that shows up under step 2b

      // Mobile/small devices: show next/submit buttons before previous
      if ($(window).width() < 768) {
        $('.gform_page_footer').each(function() {
          if ($(this).find('.gform_next_button').length && $(this).find('.gform_previous_button').length) {
            $(this).find('.gform_next_button').after($(this).find('.gform_previous_button'));
            $(this).find('.gform_next_button').wrapAll('<p>');
            $(this).find('.gform_previous_button').wrapAll('<p>');
          }

          if ($(this).find('input[type="submit"]').length && $(this).find('.gform_previous_button').length) {
            $(this).find('input[type="submit"]').after($(this).find('.gform_previous_button'));
            $(this).find('input[type="submit"]').wrapAll('<p>');
            $(this).find('.gform_previous_button').wrapAll('<p>');
          }
        });
      }
    });




    /**
     * Partial/vertical form stuff
     * - Validation
     */
    if ($('form.schedule-consultation-pre-form').length) {

      $('.field-zipcode input').prop('type', 'tel');


      // ajax for zipcode, sets custom validity on error
      var zipcodePartialAjax = function(el) {
        var $zipfield = typeof el !== 'undefined' ? el : $( '.field-zipcode input' );
        var zipVal = $zipfield.val();

        if (zipVal.length === 5 && $.isNumeric(zipVal)) {
          $.ajax({
            type: 'POST',
            url: ajaxurl,
            dataType: 'json',
            contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
            data: {'zip':zipVal, 'action':'cc_salesforce_retrieve_ziptastic'},
            success: function(data) {
              //Auto-fill the city/state from zipcode
              var $city = $('.field-city input');
              var $state = $('.field-state select');

              $city.val(data.city);
              $city.click()

              $state.val(data.state_short);
              $state.click();

              $zipfield[0].setCustomValidity("");

            },

            error: function () {
              // set custom validation for zipcode
              $zipfield[0].setCustomValidity("Please enter a valid ZIP Code.");
            },

          });
        }
      };


      // run zipcode ajax when typing
      $( '.container' ).on( 'keyup change', '.field-zipcode input' , function() {
          zipcodePartialAjax( $(this) );
      } );


      // run once on init to check for any preset zipcodes
      zipcodePartialAjax();


      $('.schedule-consultation-pre-form input[aria-required="true"]').prop('required', true);

      // State input validation
      $('.field-state select').prop('required', true).attr('maxlength', '2');
      
      $('.schedule-consultation-pre-form').on('submit', function(event) {
        // disable button on submit so no resubmit occurs
        $('.gform_button[type="submit"]').attr("disabled", true);
        event.preventDefault();
        // if (!$('.schedule-consultation-pre-form')[0].checkValidity || $('.schedule-consultation-pre-form')[0].checkValidity()) {
        //   global.ccUserInfo = ccForms.getDefaultCCUserInfo();
        //   ccForms.setPreFormData($(this));
        //   ccForms.setGlobalFormData();

        //   // send leadData from Partial form to SF
        //   initiateSFInfoPartial();
        // }

        if (!$(this).checkValidity || $(this).checkValidity()) {
          global.ccUserInfo = ccForms.getDefaultCCUserInfo();
          ccForms.setPreFormData($(this));
          ccForms.setGlobalFormData();
          // send leadData from Partial form to SF
          saveLeadInfoPartial();
        }
        // allow for return
        if($(".landing-page").length){
          setTimeout(function(){
            window.location.replace("/schedule-your-consultation/")
          },750);
          
        } else{
          setTimeout(function(){
            window.location.replace("/schedule-consultation/")
          },750);
        }
      });

    }




    /**
     * OS form stuff
     */
    if ($('form.schedule-consultation-form').length) {
       //dont set oath for preform
      setOathToken();
      global.ccUserInfo = ccForms.getDefaultCCUserInfo();
      ccForms.setGlobalFormData();
      global.dataLayer.push({ 'event': 'Form-Step-1' });
    }else if($(".pre-form").length){
      setOathToken();
       
       // add autocomplete to partial form pages 
       initMenu();
       $(".field-address input").keyup(function (event) {
         var menu = $(".us-autocomplete-pro-menu");
         //if($("#data-street")[0].innerText) clearAddressData();
           var textInput = $(".field-address input").val();
           if (textInput.length > 0) {
             menu.show();
             getSuggestions(textInput);
           } else {
             menu.hide();
           }
       });
      // add for partial mobile form 
      $("#mobile-form .field-address input").keyup(function (event) {
        var menu = $(".us-autocomplete-pro-menu");
        //if($("#data-street")[0].innerText) clearAddressData();
          var textInput = $("#mobile-form .field-address input").val();
          if (textInput.length > 0) {
            menu.show();
            getSuggestions(textInput);
          } else {
            menu.hide();
          }
      });
      
    }




    /**
     *
     */
    $(document).bind('gform_page_loaded', function(event, form_id, current_page) {
      if ($('form.schedule-consultation-form').length) {
        if(global.page2loaded == undefined){
          global.page2loaded = false;
        }
        global.ccUserInfo.currentStep = current_page;
        //handle page 2 event loading twice
        if(current_page == 2 || current_page == "2"){
          if(!global.page2loaded){
            global.dataLayer.push({ 'event': 'Form-Step-' + current_page });
          }
          global.page2loaded = true;
        } else{
          global.dataLayer.push({ 'event': 'Form-Step-' + current_page });
        }
        
      }

      // Set default scrollTo position
      let scrollToPosition = $('.gform_wrapper form').offset().top - $('.banner').height();

      // Update the form scrollTo position if we're on mobile
      if (!window.matchMedia('(min-width: 576px)').matches) {
        // Scroll to the top of the OS form

        scrollToPosition = $('.gform_body').offset().top - $('.banner').height();
      }

      let currentPage = $('.gf_step.active');

      if (currentPage !== currentPagePrev) {
        if (current_page == 2 && global.ccUserInfo.step2Valid) {
          //$('html, body').animate({
          //  scrollTop: $('.form-locate-center').offset().top - $('.banner').height(),
          //}, 250);
        } else {
          $('html, body').animate({
            scrollTop: scrollToPosition,
          }, 250);
        }
      }

      let currentPagePrev = $('.gf_step.active');

      // Scroll to top of form on error
      if ($('.gform_validation_error').length) {
        scrollToPosition = $('.gform_validation_error').offset().top - $('.banner');

        $('html, body').animate({
          scrollTop: scrollToPosition,
        }, 250);
      }
    });


    /**
     * Consultation pre-form
     * @todo: remove? not really sure what this is referencing -- .pre-form-horizontal
     */
    if ($('.pre-form-horizontal').length) {
      // Grid for address
      if (!$('.field-city').hasClass('col-lg-5 mb-0')) {
        $('.page-gather-info .gfield:not(:first)').wrapAll('<div class="row"><div class="col-sm-8"></div></div>');
        $('.field-city, .field-state, .field-zipcode').wrapAll('<div class="row"></div>');
        $('.field-city').addClass('col-lg-5 mb-0');
        $('.field-state').addClass('col-lg-4 mb-0');
        $('.field-zipcode').addClass('col-lg-3 mb-0');
        $('.page-gather-info .row li').addClass('mb-0');

        $('.field-firstName, .field-lastName').wrapAll('<div class="row"></div>');
        $('.field-firstName').addClass('col-lg-6 mb-0');
        $('.field-lastName').addClass('col-lg-6 mb-0');

        $('.field-phone, .field-email').wrapAll('<div class="row"></div>');
        $('.field-phone').addClass('col-lg-5 mb-0');
        $('.field-email').addClass('col-lg-7 mb-0');
      }

      // State input validation
      $('.field-state select').prop('required', true).attr('maxlength', '2');
    }
    

    // //show waiting icon for first step on submission
    // $('#gform_19').submit(function(){
    //     console.log('thing1');
    //     if($('#gform_page_19_1').attr('style') != 'display:none;' && $('.gfield_error').length == 0){
    //       console.log('thing2');
    //       showWaitingIcon('Please Wait');
    //     }
    // });

    /**
     * DO specific things on each page of the OS form
     * - Page 2 - Prefill form if data from Partial exist
     * - Page 2 - Logic/ajax for checking valid zipcode
     * - Page 3 - Retrieves available appts, runs initiateSFInfo()
     * - Page 3 - Displays available appts, display the appt times
     */
    $(document).bind('gform_page_loaded', function(e, form_id, current_page) {



      // Consultation form
      if ($('form.schedule-consultation-form').length) {
        $('form.schedule-consultation-form').find('input[type=text]').addClass('inspectletIgnore');

        // change zipcode input type to 'tel' for keypad on mobile
        $('.field-zipcode input').prop('type', 'tel');


        // when using the zipcode field
        $( '.container' ).on( 'keyup change', '.field-zipcode input' , function() {
          var $zipfield = $( this );
          var zipVal = $zipfield.val();

          if ( zipVal.length === 5 && $.isNumeric( zipVal ) ) {
            $.ajax({
              type: 'POST',
              url: ajaxurl,
              dataType: 'json',
              contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
              data: {'zip':zipVal, 'action':'cc_salesforce_retrieve_ziptastic'},
              success: function(data) {
                //Auto-fill the city/state from zipcode
                var $city = $('.field-city input');
                var $state = $('.field-state select');

                $city.val(data.city);
                $city.click()

                $state.val(data.state_short);
                $state.click();

                if( is__w1)
                  $zipfield.removeClass('error zipcode-error');
                else
                  $zipfield.parents('.gfield').removeClass('gfield_error zipcode-error');

              },

              error : function() {
                if( is__w1)
                  $zipfield.addClass('error zipcode-error');
                else
                  $zipfield.parents('.gfield').addClass('gfield_error zipcode-error');
              },

            });
          }


        } );
        
       
        initMenu();
        $(".field-address input").keyup(function (event) {
        var menu = $(".us-autocomplete-pro-menu");
        //if($("#data-street")[0].innerText) clearAddressData();
          var textInput = $(".field-address input").val();
          if (textInput.length > 0) {
            menu.show();
            getSuggestions(textInput);
          } else {
            menu.hide();
          }
        });
        
        
        if (current_page == 2) {
          hideWaitingIcon();
          
          if (global.ccUserInfo.firstName) {
            $('.field-firstName input').val(global.ccUserInfo.firstName);
          }

          if (global.ccUserInfo.lastName) {
            $('.field-lastName input').val(global.ccUserInfo.lastName);
          }

          if (global.ccUserInfo.phone) {
            $('.field-phone input').val(global.ccUserInfo.phone);
          }

          if (global.ccUserInfo.email) {
            $('.field-email input').val(global.ccUserInfo.email);
          }

          if (global.ccUserInfo.address) {
            $('.field-address input').val(global.ccUserInfo.address);
          }

          if (global.ccUserInfo.zipcode) {
            $('.field-zipcode input').val(global.ccUserInfo.zipcode);
          }

          if (global.ccUserInfo.city) {
            $('.field-city input').val(global.ccUserInfo.city);
          }

          if (global.ccUserInfo.state) {
            $('.field-state select').val(global.ccUserInfo.state);
          }


          // Grid for address
          if (!$('.field-city').hasClass('col-lg-5')) {

            // wraps everything in row/col divs for w2
            if(is__w2)
              $('.page-gather-info .gfield:not(:first)').wrapAll('<div class="row"><div class="col-sm-8"></div></div>');

            $('.field-city, .field-state, .field-zipcode').wrapAll('<div class="row"></div>');
            $('.field-city').addClass('col-lg-5');
            $('.field-state').addClass('col-lg-4');
            $('.field-zipcode').addClass('col-lg-3');
            $('.page-gather-info .row li').addClass('mb-0');

            $('.field-phone, .field-email').wrapAll('<div class="row"></div>');
            $('.field-phone').addClass('col-lg-5');
            $('.field-email').addClass('col-lg-7');
          }

          // State input validation
          $('.field-state select').prop('required', true).attr('maxlength', '2');

          // Show lookup center form if personal info is valid
          if ($('.gform_wrapper').hasClass('gform_validation_error')) {
            global.ccUserInfo.step2Valid = false;
          }

          if (global.ccUserInfo.step2Valid) {
            if (!$('.form-locate-center').length) {
              
              $('.page-gather-info .gform_page_footer').html(locateCenterForm);
              global.dataLayer.push({ 'event': 'Form-Step-1.5' });
              // tweak the locate center form to hide certain things
              if( is__w1 ) {
                // 100% width the `select a center` h2
                $('.page-gather-info .form-locate-center .col-sm-8').attr('class', 'col-12 form-locate-center');
                $('.page-gather-info .form-locate-center .col-sm-4').attr('class', 'd-none');
                // 100% width the center results
                $('.page-gather-info .form-locate-center-results .col-sm-7').attr('class', 'col-12 nearest-location-container');
                // hide the googlemaps
                $('.page-gather-info .form-locate-center-results .col-sm-5').addClass('d-none');
                // hide the zipcode stuff
                $('.page-gather-info .form-locate-center-lookup').addClass('d-none');
              }

              // mobile needs a bit more room on scroll
              if ($(window).width() < 768) {
                $('html, body').animate({
                  scrollTop: $('.page-gather-info .form-group.bg-light').offset().top + 250,
                }, 250);
              } else {
                $('html, body').animate({
                  scrollTop: $('.page-gather-info .form-group.bg-light').offset().top,
                }, 250);
              }


            }

            // If not coming from page 3 (location isn't yet selected), hide next button
            if (!global.ccUserInfo.centerID) {
              $('.gform_next_button').hide();
            }
          }

          let formID = $('input[name="gform_submit"]').val();
          $('#gform_target_page_number_'+formID).val(2);
          $('.gform_next_button').attr('onclick', 'jQuery("#gform_target_page_number_'+formID+'").val("2");  jQuery("#gform_'+formID+'").trigger("submit",[true]);');
          $('.gform_next_button').attr('onkeypress', 'jQuery("#gform_target_page_number_'+formID+'").val("2");  jQuery("#gform_'+formID+'").trigger("submit",[true]);');
          // $('.gform_next_button').addClass('form-locate-center-lookup');

          $('#form-locate-center-address').keydown(function(e) {
            if (e.keyCode == 13) {
              e.preventDefault();
              $.when(global.retrieveLeadInfoLock).then(function(success){
                if(success && ccForms.checkChangesToPreformData()){
                  saveUpdateLeadInfoAndRetrieveInfo();
                }else{
                  //delete preform info in case they just changed data
                  Cookie.remove('cc-pre-form');
                  Cookie.remove('userFrontEndId');
                  saveLeadInfo();
                }
              });
              lookupCenter();

              return false;
            }
          });

          if (global.ccUserInfo.zipcode) {
            $('#form-locate-center-address').val(global.ccUserInfo.zipcode);
          }

          if($('#form-locate-center-address').val() != undefined && $('#form-locate-center-address').val() != ''){
            $.when(global.retrieveLeadInfoLock).then(function(success){
              if(success && ccForms.checkChangesToPreformData()){
                saveUpdateLeadInfoAndRetrieveInfo();
              }else{
                saveLeadInfo();
              }
            });
            showWaitingIcon('Retrieving your nearest ClearChoice Centers');
            lookupCenter();
          }

       

          if (!$('.validation_error').length) {
            // $('.page-gather-info .gform_page_fields').addClass('d-none');
          } else {
            if (typeof zip !== 'undefined') {
              $('#form-locate-center-address').val(zip);
            }

            $('.form-locate-center-lookup .btn').trigger('click');
          }

          $('a[href="#form-locate-center-form"]').click(function(e) {
            e.preventDefault();
            $('.gform_next_button').hide();
            $('.form-locate-center-results').addClass('d-none');
            $('.form-locate-center-results .list-group').empty();
            $('.form-locate-center-lookup').removeClass('d-none');
            $('.form-locate-center > .row .text-muted').removeClass('d-none');
            $('.form-locate-center-lookup input[type="text"]').focus();
            $('.page-gather-info .gform_page_fields').addClass('d-none');
          });
        }

        if (current_page == 3) {
          
          $('.gform_button[type="submit"]').hide();
          $('.field-agree-to-terms').hide();
          if (global.ccUserInfo.availableAppointmentsRetrieved) {
            if (global.ccUserInfo.firstAvailableDate) {
              displayAvailableAppointments();
              window.scrollTo(0,300);
             
              // Scroll to top of form on error
              var targetNode = document.getElementById('webform-component-messages');
              var observer = new MutationObserver(function(){
                  if(targetNode.style.display != 'none'){
                    window.scrollTo(0,300);
                  }
              });
              observer.observe(targetNode, { attributes: true, childList: true });
            } else {
              $('.no-appointments').html('<div class="alert alert-danger">There aren\'t any appointments available. Please call ' + callCenterNumber + ' for assistance.</div>');
            }
          } else {
            if (!$('.loading-appointments').length) {
              $('.no-appointments').before('<li class="gfield loading-appointments mb-4"></li>');
              $.when(global.saveLeadInfoLock).then(function(success){
                if(success){
                  retrieveAppointments();

                  // Scroll to top of form on error
                  var targetNode = document.getElementById('webform-component-messages');
                  var observer = new MutationObserver(function(){
                      if(targetNode.style.display != 'none'){
                        window.scrollTo(0,300);
                      }
                  });
                  observer.observe(targetNode, { attributes: true, childList: true });
                }else{
                  $('#webform-messages').html('<div class="alert alert-danger" role="alert">There was an issue connecting to our servers. Please try again later, or call ' + callCenterNumber + ' for assistance.</div>');
                }
              });
              if(global.saveLeadInfoDone !== undefined && global.saveLeadInfoLock.state() != "resolved"){
                global.saveLeadInfoLock.resolve(global.saveLeadInfoDone);
              }
            }
          }









          /**
           * Step 3 - Click on Calendar date, display times
           */

          if(is__w2) {
          $(document).on('click', '#webFormCalendar', function() {
            var $selectObject = $('#choose-date--timelist .btn-group-grid');
            if ($(this).find('.selected').length > 0) {
              var dateInfo = $('#webFormCalendar .selected').data();

              $selectObject.empty();

              // Display the correct times
              $.each(global.ccUserInfo.availableAppointments.seminars, function(index, time) {
                if (time.date === dateInfo.year + '-' + dateInfo.month + '-' + dateInfo.day) {
                  var offset = getTimeZoneOffsets(global.ccUserInfo.availableAppointments.timezone, global.ccUserInfo.availableAppointments.timezone);
                  var myDate = getActualTime(dateInfo.year, dateInfo.month, dateInfo.day, time.start, offset);
                  let myDateText = printActualTime(myDate);

                  var filledClass = ' class="btn btn-white" ';
                  if (time.blocks.length === 0) {
                    filledClass = ' class="btn btn-white disabled slot-filled" ';
                  }

                  $selectObject.append(
                    $('<div' + filledClass + '></div>')
                      .attr({
                        "data-timeslot": time.blocks.join('.'),
                        "data-datestring": moment(myDate).format('l'),
                        "data-timestring": moment(myDate).format('h:mm A'),
                        "data-calendarstart": moment(myDate).format('YYYY-MM-DD HH:mm:ss'),
                        "data-calendarend": moment(myDate).add(1, 'h').format('YYYY-MM-DD HH:mm:ss'),
                      })
                      .html(myDateText + '<br class="time-zone-break"> ' + global.ccUserInfo.availableAppointments.timezone));
                }
              });
              if($(window).width() <= 767){
                $([document.documentElement, document.body]).animate({
                    scrollTop: ($(".select-time").offset().top -20),
                }, 500);
              }
            } else {
              global.ccUserInfo.appointment = null;
            }
          });

          // click the available appoint time slots
          $(document).on('click', '#choose-date--timelist .choose-date-timelist .btn-group > .btn:not(.disabled)', function() {
            var $this = $(this);

            global.ccUserInfo.calendarStart = $this.attr('data-calendarstart');
            global.ccUserInfo.calendarEnd = $this.attr('data-calendarend');

            $('#choose-date--timelist .choose-date-timelist .btn-group > .btn').removeClass('selected');
            $('#choose-date--timelist .choose-date-timelist .btn-group > .btn').removeClass('active');
            $this.addClass('selected');
            $this.addClass('active');

            if (global.ccUserInfo.appointment === '' || global.ccUserInfo.appointment === null) {
              let scheduledNotConfirmed = {};
              scheduledNotConfirmed.GUID = global.ccUserInfo.guid;
              scheduledNotConfirmed.isContact = (global.ccUserInfo.contactID !== '' && global.ccUserInfo.contactID !== null) ? true : false;
              if(global.ccUserInfo.page == 'adi' ) {
                scheduledNotConfirmed.TransId = global.ccUserInfo.trans_id;
                scheduledNotConfirmed.referral_id = global.ccUserInfo.referral_id;
              } else {
                scheduledNotConfirmed.TransId = 'onlinescheddateandtime';
              }
              scheduledNotConfirmed.action = 'cc_salesforce_submit_campaign_member';
              scheduledNotConfirmed.securityToken = global.securityToken;
              $.ajax({
                url: ajaxurl,
                data: scheduledNotConfirmed,
                cache: false,
                dataType: 'json',
                type: 'POST',
              });
            }

            global.ccUserInfo.appointment = $this.data('timeslot');
            global.ccUserInfo.appointmentDate = $this.data('datestring');
            global.ccUserInfo.appointmentTime = $this.data('timestring');

            global.ccUserInfo.appointmentHtmlInfo = "class = " + $this.attr("class") + " \ntimeslot = " + $this.data('timeslot') +
              "\ndatestring = " + $this.data('datestring') + "\ntimestring = " + $this.data('timestring') +
              "calendarstart = " + $this.data('calendarstart') + "\ncalendarend = " + $this.data('calendarend');

            $('.gform_button[type="submit"]').attr("disabled", false);

            $('.field-agree-to-terms').show();
            $('.gform_button[type="submit"]').show();
            if($(window).width() <= 950){
              $([document.documentElement, document.body]).animate({
                  scrollTop: ($(".field-agree-to-terms").offset().top - 20),
              }, 500);
            } else{
              $([document.documentElement, document.body]).animate({
                  scrollTop: ($(".field-agree-to-terms").offset().top - 100),
              }, 500);
            }
          });

          $(document).on('click', '.gform_button[type="submit"]', function(e) {
            e.preventDefault();
            if(!$('.field-agree-to-terms input').is(':checked')){
              $('#webform-messages').html('<div class="alert alert-danger" role="alert">Please validate that you are 18 or older.</div>');
              $('li.field-agree-to-terms').addClass('gfield_error');
              return false;
            } else{
              $('#webform-messages').html('');
              $('li.field-agree-to-terms').removeClass('gfield_error');
            }

            global.ccUserInfo.attemptsAtSubmission = 0;
            submitForm();
            //confirmationPageInit();
          });

          // w1: run all the functions to handle w1's calendar/times
          } else {
              w1_step3_2month();
              initiateStep3TwoMonthCal();
          }
        }

        // run the zipcode validator once on page load, run it after everything, so the inputs get filled
        var $zipfield = $('.field-zipcode input');


        if ( $zipfield.val().length === 5 && $.isNumeric( $zipfield.val() ) ) {
          $.ajax({
            type: 'POST',
            url: ajaxurl,
            dataType: 'json',
            contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
            data: {'zip':$zipfield.val(), 'action':'cc_salesforce_retrieve_ziptastic'},
            success: function(data) {
              $zipfield.parents('.gfield').removeClass('gfield_error zipcode-error');
            },

            error: function () {
              $zipfield.parents('.gfield').addClass('gfield_error zipcode-error');
            },

          });
        }



      }
    });




    /**
     *
     */
    $(document).bind('gform_post_render', function() {
      // Inspectlet data attributes
      inspectletDataAttributes();


      /*  // Add message area after steps
      $('.gform_body').prepend('<div id="webform-messages"></div></div>');
      $('.schedule-consultation-form_wrapper').prepend('\
        <div id="web-form-modal" class="modal fade">\
          <div class= "modal-dialog modal-md modal-dialog-centered">\
            <div class="modal-content">\
              <div class="modal-body">\
                <div class="row">\
                  <div class="col-sm-12">\
                    <div class="message-text text-center">\
                    </div>\
                  </div>\
                </div>\
                <div class="buttons">\
                </div>\
              </div>\
            </div>\
          </div>\
        </div>');


      //add cancelation form modal
      $('.schedule-consultation-form_wrapper').prepend('\
        <div id="call-me-now-form" class="modal fade">\
          <div class= "modal-dialog modal-md modal-dialog-centered">\
            <div class="modal-content">\
              <div class="modal-body">\
                <div class="row">\
                  <div class="message-text text-center">\
                    <p>Verify your information here below. Then, press continue. A ClearChoice representative will call you shortly<p>\
                  </div>\
                </div>\
                <div class="row">\
                  <div class="call-me-now-form">\
                    <label>First Name</label>\
                    <input class="required" id="call-me-now-firstName" title="First Name" type="text" >\
                    <label>Last Name</label>\
                    <input class="required" id="call-me-now-lastName" title="Last Name" type="text">\
                    <label>Phone Number</label>\
                    <input class="required" id="call-me-now-phone" title="Phone" type="tel">\
                  </div>\
                </div>\
                <div class="buttons">\
                  <div class="row"><button class="continue btn btn-sm btn-outline-primary px-3 py-2 temp">Continue</button></div>\
                  <div class="row"><button class="cancel btn btn-sm btn-outline-primary px-3 py-2 temp">Cancel</button></div>\
                </div>\
              </div>\
            </div>\
          </div>\
        </div>');
      */


      // Move descriptions below labels (w2 only)
      if( is__w2 ) {
        $('.gfield_description').each(function(i, e) {
          const fieldDescription = $('<div>').append($(e).clone()).remove().html();
          $(e).siblings('label.gfield_label').after(fieldDescription);
          $(e).remove();
        });
      }

      // Consultation form
      if ($('form.schedule-consultation-form').length) {
        $('form.schedule-consultation-form').find('input[type=text]').addClass('inspectletIgnore');
        if (Cookie.get('GUID') && Cookie.get('GUID') !== '') {
          global.ccUserInfo.guid = window.atob(Cookie.get('GUID'));
        }

        // if ($('.number-of-years').length) {
        //   $('.number-of-years').appendTo('.current-dental-solutions .gfield_checkbox li:first label');
        //   $('.number-of-years input[type="text"]').prop('type', 'hidden');
        //   $('.number-of-years .ginput_container').after(`
        //     <div class="btn-group" data-toggle="buttons">
        //       <label class="btn btn-white">
        //         <input type="radio" name="options" autocomplete="off"> 0 - 6 mo
        //       </label>
        //       <label class="btn btn-white">
        //         <input type="radio" name="options" autocomplete="off"> 7 mo - 2 yrs
        //       </label>
        //       <label class="btn btn-white">
        //         <input type="radio" name="options" autocomplete="off"> 3 - 5 yrs
        //       </label>
        //       <label class="btn btn-white">
        //         <input type="radio" name="options" autocomplete="off"> 6 - 10 yrs
        //       </label>
        //       <label class="btn btn-white">
        //         <input type="radio" name="options" autocomplete="off"> 11+  yrs
        //       </label>
        //     </div>
        //   `);
        // }

        $('.schedule-consultation-form').on('submit', function(e) {
          //validation modal popup code
          var theForm = $( 'form.schedule-consultation-form' );
          var formID = theForm.find("input[name='gform_submit']").val();
          var currentPageField = $("#schedule-your-free-consultation-form-container input[name='gform_source_page_number_" + formID + "']");
          var curstep = currentPageField.val();
          var stepClasses = $("#gform_page_" + formID + "_" + curstep).attr("class");
          var stepName = stepClasses.split(" ")[1];
          var errors = '';
          var errorHTML = '';
          var messageBox = $("#webform-component-messages");
          errors = ccForms.validateSection(stepName);
          //some logic for comm preference checkbox
          if( errors.length > 0){
            //Fill the message box with the errors
            errorHTML = '<h4>Please fix the following:</h4>';
            for (var i = 0; i < errors.length; i++) {
              errorHTML += "<p>" + errors[i] + "</p>";
            }

          

            messageBox.find(".message-text").html(errorHTML);

            ccForms.showMessageBox();
            $('.dismiss').click(function(){
              ccForms.hideMessageBox();
            });
            return false;
          }

          showWaitingIcon('Please Wait');
          if ($('.field-questions').length) {
            showWaitingIcon('Please Wait');
            if ($('.field-questions input:checked').val() === 'None of the Above') {
              e.preventDefault();
              $('#webform-component-messages .message-text').html('<h4>Please fix the following:</h4><p class="mb-0 text-center">Based on your selection, it doesn’t look like you’re a candidate for dental implants. But if you’d like to talk to someone about it, call us at ' + callCenterNumber + '</p>');
              ccForms.showMessageBox();
              $('.dismiss').click(function(){
                ccForms.hideMessageBox();
              });
              hideWaitingIcon();
              return false;
            }

            // if ($('.number-of-years .btn-group .active').length) {
            //   $('.number-of-years input[type="hidden"]').val($('.number-of-years .btn-group .active').text().trim());
            // }

            // reset the questions between calls in case they changed
            global.ccUserInfo.fewQuestions = {};

            $('.field-questions input[name!="options"]').each(function() {
              if ($(this).is(':checked')) {
                global.ccUserInfo.fewQuestions[$(this).val()] = true;
              }

              if ( $(this).is('input[type=number]') && $(this).val() ) {
                const numberLabel = $('label[for="' + this.id + '"]').text();
                global.ccUserInfo.fewQuestions[numberLabel] = $(this).val();
              } else if ( !$(this).is(':checkbox') && $(this).val() ) {
                const inputLabel = $('label[for="' + this.id + '"]').text();
                global.ccUserInfo.fewQuestions[inputLabel] = $(this).val();
              }
            });
            hideWaitingIcon();
          }

          global.ccUserInfo.availableCenters = global.ccUserInfo.availableCenters;
          global.ccUserInfo.centerID = global.ccUserInfo.centerID;

          if ($('.field-firstName input').val()) {
            global.ccUserInfo.firstName = $('.field-firstName input').val();
            global.ccUserInfo.lastName = $('.field-lastName input').val();
            global.ccUserInfo.phone = $('.field-phone input').val();
            global.ccUserInfo.email = $('.field-email input').val();
            global.ccUserInfo.address = $('.field-address input').val();
            global.ccUserInfo.zipcode = $('.field-zipcode input').val();
            global.ccUserInfo.city = $('.field-city input').val();
            global.ccUserInfo.state = $('.field-state select').val();


            // validate zipcode/state and show modal if invalid
            var modal_text = '';
            var show_modal = false;



            if (validStates.indexOf($('.field-state select').val()) === -1) {
                show_modal = true;
                modal_text += '<p class="mb-0 text-center">Please enter a valid state.</p>';
            }


            if( $('.field-zipcode').hasClass('zipcode-error') ) {
              show_modal = true;
              modal_text += '<p class="mb-0 text-center">Please enter a valid zipcode.</p>';
            }


            // show modal if validation fails
            if( show_modal ) {
              e.preventDefault();
              $('#modal-message .modal-body').html(modal_text);
              $('#modal-message').modal('show');
              return false;
            }



            if (global.ccUserInfo.firstName && global.ccUserInfo.lastName && global.ccUserInfo.phone && global.ccUserInfo.email && global.ccUserInfo.address && global.ccUserInfo.zipcode && global.ccUserInfo.city && global.ccUserInfo.state) {
              global.ccUserInfo.step2Valid = true;
            }
          }
        });
      } // End of consultation form
    });





    /**
     * Confirmation page of OS form
     * - Calendar/appt details
     * - Sets directions to google maps
     */
    $( document ).bind( 'gform_confirmation_loaded', function( event, form_id ) {
      
      $( ".form-page-instructions" ).show();
      
      // code to be trigger when confirmation page is loaded
      global.dataLayer.push({'timesPromptedOnDateClick':global.ccUserInfo.numberOfPrompts});
      global.dataLayer.push({ 'event': 'Form-Step-confirmation' });
      // add fix for animation
      $(".text-accent-primary").get(0).scrollIntoView();
      //number of prompt to call messages a user clicks on before scheduling
     
      // Accordant Pixel: ClearChoice Paid Form Step 4 Confirmation Page (Thank You Confirmation page)
      var accordantLeadId = global.ccUserInfo.guid;
      
      var confirmation = $( '.gform_confirmation_message' );
      
      //google maps button
      var selectedCenter = global.ccUserInfo.availableCenters[ccUserInfo.centerID];
      var googleMapButton = '//maps.google.com/?q=' + selectedCenter.address + ' ' + selectedCenter.address2 + ' , ' + selectedCenter.city + ' , ' + selectedCenter.state + ' , ' + selectedCenter.zipcode;
      $( '.confirmation_button.directions' ).attr( 'href', googleMapButton );

      confirmation.find( '.confirmation-details .date' ).html( global.ccUserInfo.appointmentString );
      confirmation.find( '.confirmation-details .address' ).html( global.ccUserInfo.addressString );

      //AddToCalendar
      $( '.atc_date_start' ).html( global.ccUserInfo.calendarTimeString );
      $( '.atc_date_end' ).html( global.ccUserInfo.calendarTimeEndString );
      $( '.atc_timezone' ).html( jstz.determine().name() );
      $( '.atc_location' ).html( selectedCenter.address + ' ' + selectedCenter.address2 + ', ' + selectedCenter.city + ', ' + selectedCenter.state + ' ' + selectedCenter.zipcode );
      //window.addtocalendar.load();
      
      //check for cookie for optimizely experiment 
      //var testTextConfirmationPopUp = Cookie.get('testTextConfirmationPopUp');
      //var testTextConfirmationCheckBox = Cookie.get('testTextConfirmationCheckBox');
      //if(testTextConfirmationPopUp && !testTextConfirmationCheckBox){
        //show the text message consent popup
        //saveCampaign('Text_v2');
        //ccForms.showTextMessageTermsBox();
      //}
      
    } );




    /**
     * Run all the functions needed for the Confirmation page
     */

    let confirmationPage = function() {
      confirmationPageCheckboxClick();
      confirmationPageTextClick();
      //confirmationPageSave(); // removed per CC-227
      confirmationPageInit();

      // Change Call Center # to user's call center
      //$('.schedule-consultation-form .cancel-change-appt').html('To change or cancel your appointment, please call us at ' + callCenterNumber + ' at least 48 hours prior to your scheduled consultation time.');

    };


  },
};
