import {ccMenu} from './w1-menu';
import {helpers} from '../global/setUserParamsLegacy';

import 'slick-carousel';



/**
 * W1 specific JS
 * pulled from w1/modules/site.js
 */

export let Site = {
    challengeElement: null,
    context: null,
    largeScreen: undefined,
    medScreen: undefined,
    previousScreenSize: undefined,
    viewportWidth: $(window).width(),
    viewportHeight: $(window).height(),



    /**
     * Initialize site
     */
    init: function () {


      /**
       * set user parameters, cookies, tracking
       */
      Site.newSession();
      Site.setUserParams();


      // window resize, mainly for sliders/carousels?
      Site.setWindowResizeListener();
      Site.checkWindowSize();





      /**
       * sticky menu
       */
      //set viewport variables first
      Site.setViewportVariables();
      ccMenu.addStickyMenu();
      Site.setViewportVariables();
      ccMenu.hamburgerHelper();





      /**
       * Careers page
       */
      Site.urlParamAppend();


      /**
       * Locations
       */

      // Locations Page Template map animation
      Site.bindLocationMapAnimation();
      // Single Location - create location map
      Site.addLocationMap();
      // Single Location - toggle doctor details
      Site.toggleDoctorDetails();
      // Single Location - smooth scrolling
      Site.smoothScroll();

      /**
       * init video/modals
       */
      //show video popup sitewide (including home page)
      Site.showVideoPopup();
      Site.setVideoModalEventHandlers();
      Site.initSlider();
      Site.initMobileSlider();
      window.onload = function() {
        Site.alignVideoModules();
      };


    },












  /*****************************************************************************************
   * Cookies, tracking
   *****************************************************************************************/

  newSession: function() {
    var landingPageCookie = helpers.getCookie( 'session-landing_page' );

    if ( helpers.getCookie( 'session-landing_page' ) === '' || helpers.getCookie( 'session-landing_page' ) == 'undefined' ) {
      var landingPage = [location.protocol, '//', location.host, location.pathname].join('');

      var logData = {};
      logData.action = 'logNewSession';
      logData.landingPage = landingPage;

      jQuery.ajax( {
        type: 'POST',
        url: ajaxurl,
        data: logData,
        success: function( msg ) {
          //console.log(msg);
        },
        error: function( msg ) {

        },
      } );

      helpers.setCookie( 'session-landing_page', landingPage , -1 );
    }
  },


  pushTrackers: function(trandsIdSplitArray,transIdArray){
    $.each(trandsIdSplitArray, function(key,value){
      var tracker = value;
      transIdArray.push(value);
    });
    return transIdArray;
  },

  checkAddTracker: function(transIdArray){
    var addTracker = true;
    $.each(transIdArray,function(index,transId){
      if(index == transId){
        addTracker = false;
      }
    });
    return addTracker;
  },


  setUserParams: function() {
    var d = new Date();
    var dExperation = new Date();
    dExperation = dExperation.setFullYear( 2100 );
    var cookies = {};
    var setTrackerCookie = false;
    var transIdRegex = RegExp("trans_id([[0-9]*]|(%5B[0-9]*%5D))");
    var transIdCommaRegex = RegExp(",");
    var transIdCommaBase64Regex = RegExp("(%2C)");
    var transIdArray = [];

    cookies.ClearChoiceTracker_v2 = [];
    cookies.ClearChoiceTracker_v2.campaigns = [];
    cookies.ClearChoiceTracker_v2.referal = [];
    cookies.ClearChoiceTracker_v2.utm_term = [];
    var tracker = null;
    var SearchString = window.location.search.substring( 1 );
    var VariableArray = SearchString.split( '&' );
    for( var i = 0; i < VariableArray.length; i++ ){
      var KeyValuePair = VariableArray[i].split( '=' );
      //needs to account for any variation of on indexes in []
      if( KeyValuePair[ 0 ] == 'trans_id' || transIdRegex.test(KeyValuePair[ 0 ])){
        if(transIdCommaRegex.test(KeyValuePair[ 1 ]) || transIdCommaBase64Regex.test(KeyValuePair[ 1 ])){
          var trandsIdSplitArray;
          if(transIdCommaBase64Regex.test(KeyValuePair[ 1 ])){
            trandsIdSplitArray = KeyValuePair[ 1 ].split('%2C');
          }else{
            trandsIdSplitArray = KeyValuePair[ 1 ].split(',');
          }
          $.each(trandsIdSplitArray, function(key,value){
            tracker = value;
            if(value != ''){
              transIdArray.push(value);
            }
          });
        } else{
          tracker = KeyValuePair[ 1 ];
          if(KeyValuePair[ 1 ] != ''){
            transIdArray.push(KeyValuePair[ 1 ]);
          }
        }

      }
    }

    var all = document.cookie;

    if( all !== '' ) {
      var list = all.split( '; ' );
      for( var n = 0; n < list.length; n++ ) {
        var cookie = list[ n ];
        var p = cookie.indexOf( '=' );
        var name = cookie.substring( 0, p );
        var value = cookie.substring( p + 1 );
        if( value != 'null' && value != '0' ) {
          if( name == 'ClearChoiceTracker_v2' ) {
            value = helpers.unserialize( helpers.base64_decode( decodeURIComponent( value ) ) );
          } else if ( name == 'userLocation' ) {
            value = helpers.unserialize( decodeURIComponent( value ) );
          } else if ( name == 'GUID' ) {
            value = helpers.unserialize( helpers.base64_decode( decodeURIComponent( value ) ) );
          } else {
            value = decodeURIComponent( value );
          }
          cookies[ name ] = value;
        }
      }
    }

    var phoneNumber = campaigns.global;
    if( typeof cookies.GUID != 'undefined' && cookies.GUID !== '' ) {
      $( 'input[name="GUID"]' ).val( cookies.GUID );
    }

    var addTracker = true;
    if ( typeof cookies.ClearChoiceTracker_v2 != 'undefined' && typeof cookies.ClearChoiceTracker_v2.campaigns != 'undefined' ) {
      if ( typeof cookies.ClearChoiceTracker_v2.campaigns.Direct == 'undefined' ) {
        setTrackerCookie = true;
        cookies.ClearChoiceTracker_v2.campaigns.Direct = Math.round( d.getTime() / 1000 );
      }

      var tester = new Array(
        null,
        10000000000
      );
      var ClearChoiceTracker = cookies.ClearChoiceTracker_v2.campaigns;
      for ( var index in ClearChoiceTracker ) {
        var timestamp = ClearChoiceTracker[ index ];
        if( index !== 'Direct' && timestamp < tester[ 1 ] ) {
          tester[ 0 ] = index;
          tester[ 1 ] = timestamp;
        }

        if( transIdArray.length > 0 && index == tracker ) {
          addTracker = Site.checkAddTracker(transIdArray);
        }
      }

      if ( typeof campaigns[ tester[ 0 ] ] != 'undefined' && campaigns[ tester[ 0 ] ] !== '' ) {
        if ( campaigns[ tester[ 0 ] ].parentPhone !== '' && campaigns[ tester[ 0 ] ].campaignPhone !== '') {

          // (888 numbers should always take precedence)
          if( campaigns[ tester[ 0 ] ].parentPhone > campaigns[ tester[ 0 ] ].campaignPhone ) {
            phoneNumber = campaigns[ tester[ 0 ] ].parentPhone;
          } else {
            phoneNumber = campaigns[ tester[ 0 ] ].campaignPhone;
          }

        } else if ( campaigns[ tester[ 0 ] ].parentPhone !== '' ) {
          phoneNumber = campaigns[ tester[ 0 ] ].parentPhone;
        } else if ( campaigns[ tester[ 0 ] ].campaignPhone !== '' ) {
          phoneNumber = campaigns[ tester[ 0 ] ].campaignPhone;
        }
      }
    }

    if(transIdArray.length > 0 && addTracker){
      setTrackerCookie = true;
      $.each(transIdArray,function(index,transId){
        cookies.ClearChoiceTracker_v2.campaigns[ transId ] = Math.round( d.getTime() / 1000);
      });
    }

    var referrer = document.referrer;
    if ( referrer !== '' && referrer.match( /^(http:\/\/www.|https:\/\/www.|ftp:\/\/www.|www.){1}([0-9A-Za-z]+\.)/i ) !== null && referrer.match( /.*clearchoice.*\.com.*/i ) === null ) {
      var addReferrer = true;
      for( i in cookies.ClearChoiceTracker_v2.referal ) {
        if( i == helpers.md5( referrer ) ) {
          addReferrer = false;
        }
      }
      if( addReferrer === true ) {
        setTrackerCookie = true;
        var newReferrer = [];
        newReferrer.uri = referrer;
        newReferrer.time = Math.round( d.getTime() / 1000 );
        cookies.ClearChoiceTracker_v2.referal[ helpers.md5( referrer ) ] = newReferrer;
      }
    }

    //Grab UTM term from url
    var utmTerm = '';
    var utmMedium = '';
    try {
      let urlParams = helpers.getQueryParams( window.location.search );
      utmTerm = urlParams.utm_term;
      utmMedium = urlParams.utm_medium;
    }
    catch( err ) {
      //Fail Silently. Todo: Send alert to GA
    }

    if ( utmTerm && utmTerm !== '' & utmMedium !== 'listing' && referrer.match( /^(http:\/\/www.|https:\/\/www.|ftp:\/\/www.|www.){1}([0-9A-Za-z]+\.)/i ) !== null && referrer.match( /.*clearchoice.*\.com.*/i ) === null ) {
      setTrackerCookie = true;
      var newUtmTerm = [];
      newUtmTerm.source = referrer;
      newUtmTerm.keyword = utmTerm;
      newUtmTerm.landing_page = window.location.protocol + '//' + window.location.hostname + window.location.pathname;
      newUtmTerm.time = Math.round( d.getTime() / 1000 );

      cookies.ClearChoiceTracker_v2.utm_term[ helpers.md5( utmTerm ) ] = newUtmTerm;
    }

    if ( setTrackerCookie === true ) {
      helpers.setCookie( 'ClearChoiceTracker_v2', helpers.base64_encode( helpers.serialize( cookies.ClearChoiceTracker_v2 ) ), dExperation );
    }

    //Set Phone number based on last trans_id they had
    if( tracker !== null && campaigns[ tracker ] !== null && campaigns[ tracker ] !== '' && typeof campaigns[ tracker ] != 'undefined' ) {

      if ( campaigns[ tracker ].parentPhone !== '' && campaigns[ tracker ].campaignPhone !== '' ) {
        // (888 numbers should always take precedence)
        if( campaigns[ tracker ].parentPhone > campaigns[ tracker ].campaignPhone ) {
          phoneNumber = campaigns[tracker].parentPhone;
        } else {
          phoneNumber = campaigns[tracker].campaignPhone;
        }
      } else if ( campaigns[ tracker ].parentPhone !== '' && campaigns[ tracker ].campaignPhone === '' ) {
        phoneNumber = campaigns[ tracker ].parentPhone;
      } else if ( campaigns[ tracker ].campaignPhone !== '' && campaigns[ tracker ].parentPhone === '' ) {
        phoneNumber = campaigns[ tracker ].campaignPhone;
      }
      helpers.setCookie( 'campaign_phone_number', phoneNumber , dExperation );
    } else if ( typeof cookies.campaign_phone_number != 'undefined' && cookies.campaign_phone_number !== null ) {
      phoneNumber = cookies.campaign_phone_number;
    }

    let formattedPhoneNumber = helpers.format_phonenumber( phoneNumber, '###-###-####' );

    if ( formattedPhoneNumber !== '' ) {
      $( '#header .callToday span a' ).attr( 'href', 'tel:' + phoneNumber ).html( formattedPhoneNumber ).addClass( 'trans_id' );
      $( '.dynamicPhone span a' ).attr( 'href', 'tel:' + phoneNumber ).html( formattedPhoneNumber ).addClass( 'trans_id' );

      // Code to fix phone number issue with ClearHeads experiment 17 (4-5-17)
      // Remove if/when experiement is over
      var test17Count = 0;
      var chTest17intvl = setInterval( function() {
        if ( typeof $( '#nav-sticky-phone-number' ) != 'undefined' && $( '#nav-sticky-phone-number' ).length > 0 ) {
          $( '#nav-sticky-phone-number' ).attr( 'href', 'tel:' + phoneNumber ).html( formattedPhoneNumber ).addClass( 'trans_id' );
          clearInterval( chTest17intvl );
        } else if ( test17Count > 30 ) {
          clearInterval( chTest17intvl );
        }
        test17Count++;
      }, 100 );
    }

    //Set the trans_id cookie
    if ( tracker !== null ) {
      helpers.setCookie( 'trans_id', tracker, dExperation );
    }
  },












  /*****************************************************************************************
   * Window Resize/Carousels
   *****************************************************************************************/



  setWindowResizeListener: function() {
    $( window ).resize( function() {
      Site.checkWindowSize();
      Site.alignVideoModules();
    } );
  },


  checkWindowSize: function() {
    if ( $( window ).width() >= 644 && $( window ).width() <= 784 ) {
      Site.medScreen = true;
    } else {
      Site.medScreen = false;
    }

    if ( $( window ).width() > 784 ) {
      Site.largeScreen = true;
    } else {
      Site.largeScreen = false;
    }
    Site.doesCarouselNeedToRender();
  },


  doesCarouselNeedToRender: function() {
    if( Site.largeScreen != Site.previousScreenSize ){
      Site.previousScreenSize = Site.largeScreen;
      Site.renderCarousel();
    }
  },

  renderCarousel: function() {
    if( Site.largeScreen ) {
      $( '.mobile-carousel' ).hide();
      $( '.desktop-carousel' ).show();
      $( '.desktop-carousel' ).slick( {
        arrows: false,
        dots: true,
      } );
    } else {
      $( '.desktop-carousel' ).hide();
      $( '.mobile-carousel' ).show().slick( {
        arrows: false,
        dots: true,
      } );
    }
  },




  /**
   * This Method vertically aligned the video and image modules content with eachother
   *
   * @return null
   */
  alignVideoModules: function() {
    // comma seperated list of page ID's to not use the image/video vertical alignment on.
    var pageIdsToExclude = '.page-id-94';

    $( '.media-copy' ).each( function() {
      $( this ).find( '.copy, .text-with-image_image, .text-with-video_video' ).removeAttr( 'style' );
    } );

    if( $( pageIdsToExclude ).length <= 0 && ( Site.medScreen || Site.largeScreen ) && $( '.media-copy' ).length >= 1 ) {
      $( '.media-copy' ).each( function() {
        var $copy = $( this ).find( '.copy' );
        var copyHeight = $copy.height();
        var boxHeight = 0;
        var topMargin = 0;
        var $sibling = {};
        if ( $copy.siblings( '.text-with-video_video' ).length >= 1 ) {
          boxHeight = $( '.text-with-video_video' ).height();
          $sibling = $copy.siblings( '.text-with-video_video' );
        }
        if ( $copy.siblings( '.text-with-image_image' ).length >= 1 ) {
          boxHeight = $( '.text-with-image_image' ).height();
          $sibling = $copy.siblings( '.text-with-image_image' );
        }
        if ( copyHeight < boxHeight ) {
          topMargin = ( boxHeight - copyHeight ) / 2;
          $copy.css({ marginTop: topMargin });
          $sibling.css({ marginTop: 'auto' });
        } else if ( copyHeight > boxHeight ) {
          topMargin = ( copyHeight - boxHeight ) / 2;
          $sibling.css({ marginTop: topMargin });
          $copy.css({ marginTop: 'auto' });
        }
      } );

    }
  },









  /*****************************************************************************************
   * Careers Pages
   *****************************************************************************************/

  // seems to be used for the sort by dept/location btns
  urlParamAppend: function() {
    $( '.js-apply-url-parameter' ).click( function() {
      var $this = $( this );

      // Get anchors parameter string
      var paramString = $this.data( 'param' );
      var anchor = $this.data( 'anchor' );

      // Split the query string to get kep value
      var paramArray = paramString.split( '=' );
      var paramKey = paramArray[0];
      var paramVal = paramArray[1];

      // Get the current URL Query
      var currentQueryString = location.search;

      // Initialize the new string
      var queryString = '';

      // Determine whether we're starting the params or appending more
      if ( currentQueryString.length > 0 ) {
        queryString = '&';
      } else {
        queryString = '?';
      }

      if ( currentQueryString.indexOf( paramKey ) > -1 ) {
        // Replace the same query param
        var regex = new RegExp( paramKey + '=[^&$]*', 'i' );
        queryString = location.search.replace( regex, paramKey + '=' + paramVal );
      } else {
        // Add a new query param
        queryString = currentQueryString + queryString + paramKey + '=' + paramVal;
      }

      // Redirect to new url
      location.href = queryString + "#" + anchor;

      // Don't obey natural url on link
      return false;
    } );
  },







  /*****************************************************************************************
   * Location Pages, + gmap
   *****************************************************************************************/


  /**
   * Location Page map animations (Template: page-template-locations )
   */
  bindLocationMapAnimation: function() {
    //bind animations
    $( '#locationMap .location-rollover' ).mouseenter( function() {
      var mapPopup = $( '.mapPopup' );

      mapPopup.stop().hide();
      mapPopup.css( {
        'top': ( this.getAttribute( 'data-map-y' ) - 60 ) + 'px',
        'left': ( this.getAttribute( 'data-map-x' ) - 80 ) + 'px',
        'opacity': '0',
      } );
      var locationName = $( this ).parent().find( 'span' ).html();
      mapPopup.find( 'span' ).html( locationName );

      mapPopup.find( 'a' ).get( 0 ).href = this.getAttribute( 'data-link' );

      mapPopup.show().animate( {
        opacity: '1',
        top: '-=25',
      }, 200, function() {
        // Animation complete.
      } );

    } );
    $( '#locationMap .mapPopup' ).mouseleave( function(){
      $( this ).fadeOut();
    } );

    /* Map Page 2 functions */
    if ( $( '#locationMap2' ).length > 0 ) {
      var locations = $( '#locationMap2 .location-rollover' );

      // Hard coded groups of locations...
      // probably should be automated somehow but can't make it work in a good way right now
      // The following is a link to springboard the automation.
      // http://stackoverflow.com/questions/29916874/can-you-tell-if-one-element-is-touching-another-using-javascript
      var groups = [
        [ 1, 2, 4, 5 ],
        [ 10, 11, 12 ],
        [ 14, 15, 29 ],
        [ 22, 25 ],
        [ 23, 27 ],
        [ 24, 26 ],
      ];

      // Calculated centers of groups, should work no matter the groups array is created
      var centers = [];
      groups.forEach( function( value, index ) {
        var X = 0;
        var Y = 0;

        value.forEach( function( i ) {
          X += parseInt( locations[ i ].getAttribute( 'data-map-x' ) );
          Y += parseInt( locations[ i ].getAttribute( 'data-map-y' ) );
        });

        X = Math.floor( X / value.length );
        Y = Math.floor( Y / value.length );

        centers.push({
          x: X,
          y: Y,
        } );
      } );

      var genRow = function( element ) {
        return ''+
          '<a class="row" href="' + element.getAttribute( 'data-link' ) + '">' +
          '<div class="rowTitle">' + element.getAttribute( 'data-title' ) + '</div>' +
          '<span class="rowPhone">' + element.getAttribute( 'data-phone' ) + '</span>' +
          '<span class="learnMore">Learn More ></span>' +
          '</a>';

      };

      var curIndex = -1;
      var curGroup = -2;
      var mouseOver = false;
      var fadeTimeout;
      var showMapPopup = function( element, index, group ) {
        var mapPopup = $( '.mapPopup' );
        window.clearTimeout( fadeTimeout );

        if ( curIndex != index && curGroup != group ) {
          mapPopup.stop().hide();

          mapPopup.find( '.rows' ).html( '' );
          mouseOver = false;

          var loc = {
            x: 0,
            y: 0,
          };

          if ( group != -1 ) {
            // Populate popup with group data
            groups[ group ].forEach( function( value, index ) {
              mapPopup.find( '.rows' ).append( genRow( locations[ value ] ) );
            });


            loc.x = centers[ group ].x;

            loc.y = centers[ group ].y;
          } else {
            // Populate with element data
            mapPopup.find( '.rows' ).html( genRow( element ) );

            loc.x = element.getAttribute( 'data-map-x' );
            loc.y = element.getAttribute( 'data-map-y' );
          }

          loc.x -= Math.floor( mapPopup.outerWidth() / 2 );
          loc.y -= mapPopup.outerHeight() + 12 + 38;

          mapPopup.css({
            'top': loc.y + 'px',
            'left': loc.x + 'px',
          });
        }

        curIndex = index;
        if ( group != -1 ) {
          curGroup = group;
        } else {
          curGroup = -2;
        }

        mapPopup.fadeIn( 200 );

      };

      // Bind mouseover
      locations.mouseenter( function() {
        var src = $( this ).attr( 'src' ).replace( 'blue', 'yellow' );
        $( this ).attr( 'src', src );

        var index = $( this ).attr( 'data-index' );
        var group = -1;
        groups.forEach( function( value, groupIndex ) {
          if ( value.indexOf( index ) != -1 ) {
            group = groupIndex;
          }
        } );

        showMapPopup( this, index, group );
      } ).mouseleave( function() {
        var src = $( this ).attr( 'src' ).replace( 'yellow', 'blue' );
        $( this ).attr( 'src', src );

        if ( !mouseOver ) {
          fadeTimeout = window.setTimeout( function() {
            $( '#locationMap2 .mapPopup' ).fadeOut();
            curIndex = -1;
            curGroup = -2;
          }, 200 );
        }
      } );

      $( '#locationMap2 .mapPopup' ).mouseenter( function() {
        mouseOver = true;
        $( '#locationMap2 .mapPopup' ).stop().fadeIn();
        window.clearTimeout( fadeTimeout );
      } ).mouseleave( function() {
        fadeTimeout = window.setTimeout( function() {
          $( '#locationMap2 .mapPopup' ).fadeOut();
          curIndex = -1;
          curGroup = -2;
        }, 200 );
      } );

      // Bind state accordion links
      $( '#locationMap2 .item-list .state-label' ).click( function() {
        $( this ).next().slideToggle();
        var collapse = $( this ).children()[ 0 ];
        if ( collapse.innerHTML == '+' ) {
          collapse.innerHTML = '-';
        } else {
          collapse.innerHTML = '+';
        }
      } );

      // Bind expandAll and collapseAll links
      $( '#expandAll' ).click( function() {
        $( '#locationMap2 .item-list .collapsible' ).slideDown();
        $( '#locationMap2 .item-list .state-label .collapse' ).each( function( index, item ) {
          item.innerHTML = '-';
        } );
        return false;
      } );

      $( '#collapseAll' ).click( function() {
        $( '#locationMap2 .item-list .collapsible' ).slideUp();
        $( '#locationMap2 .item-list .state-label .collapse' ).each( function( index, item ) {
          item.innerHTML = '+';
        } );
        return false;
      } );
    }
  },

  /**
   * Generates the actual google map
   */
  addLocationMap: function() {
    //var locationMap = document.getElementById( 'googleMap' );
    var $locationMap = $( '#googleMap' );
    if ( $locationMap.length ) {
      var data = JSON.parse( $locationMap.attr( 'dataMap' ) );
      var locationLatLng = new google.maps.LatLng( data.latitude, data.longitude );
      var initializeMap = function() {
        var mapOptions = {
          scrollwheel: false,
          mapTypeControl: false,
          zoom: 10,
          center: locationLatLng,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
        };
        let map = new google.maps.Map( document.getElementById( 'googleMap' ), mapOptions );
        var marker = new google.maps.Marker( {
          map: map,
          position: locationLatLng,
          icon: new google.maps.MarkerImage(
            data.markerImg,
            new google.maps.Size( 34, 56 ),
            new google.maps.Point( 0, 0),
            new google.maps.Point( 17, 45 )
          ),
          title: data.city,
        } );
        google.maps.event.addListener( marker, 'click', function() {
          document.location.href=data.directionsURL;
        } );
        map.setCenter( locationLatLng );
      };
      google.maps.event.addDomListener( window, 'load', initializeMap );
    }
  },

  /** * Toggle Doctor Details (locations page)
   */
  toggleDoctorDetails: function() {
    var toggleButton = $( '.js-toggle-details' );
    var doctorId = '';

    $( '#doctorsList' ).addClass( 'displayed' );

    toggleButton.on( 'click', function () {
      let $this = $( this );
      doctorId = $this.attr( 'data-doctorid' );

      var $doctorIdDiv = $( '.js-doctor-' + doctorId );

      ( $doctorIdDiv ).addClass( 'displayed' );
      $( '#doctorsList').removeClass( 'displayed' );
    } );

    $("button.js-toggle-details.button").click(function() {
    $('html,body').animate({
        scrollTop: $("#doctors").offset().top},
        'fast');
    });

    $('.js-back-to-doctors').on('click', function() {
      var $doctorIdDiv = $( '.js-doctor-' + doctorId );
      ( $doctorIdDiv ).removeClass( 'displayed' );
      $( '#doctorsList' ).addClass( 'displayed' );
      doctorId = '';
      $doctorIdDiv = '';
    } );
  },


  smoothScroll: function() {
    $( 'a.js-smooth-scroll' ).click( function() {
      var link = this.hash;
      $( 'html, body' ).animate( { scrollTop: $( link ).offset().top - 120 }, 1200 );
      return false;
    } );
  },
















  /*****************************************************************************************
   * Videos/Modal
   *****************************************************************************************/




  /**
   * Shows the video modal
   * Uses bootstrap-modal.js (included globally) and override the defaults.
   *
   * @param  object Pass in an object with the type and video src
   *
   * @return null
   */
  showModal: function( options ) {

    //set default variables
    var defaults = {
      type: false, // video, img, content, gallery
      src: false, // e.g. //www.youtube.com/embed/D8E7G0a4_Ao?autoplay=1
      contentSelector: false, //any css selector for an element (required for type: 'content')
    };

    //override defaults with user entered options
    var settings = $.extend( {},this.defaults, options );

    //set private variables
    var showModal = this;
    var modal = $( '.modalHidden' ).clone( true, true ).removeClass( 'modalHidden' );

    this.setup = function() {
      //make sure the data sent to this method is correct
      this.dataCheck();
      //remove unused html from modal
      this.modalCleanup();
      //show the modal
      modal.appendTo( 'body' );
      //add the content to the modal
      this.addContent();
      //bind events to the modal
      this.bindEvents();
    };

    this.bindEvents = function(){
      //remove event functions
      modal.find( '.modalOpacity' ).click( function() {
        $( this ).closest( '.modal' ).remove();
      } );
      modal.find( '.modalClose' ).click( function() {
        $( this ).closest( '.modal' ).remove();
      } );

      //next and previous functions
      modal.find( '.modalNext, .modalPrev' ).click( function( e ) {
        e.preventDefault();
        var activeIndex = parseInt( modal.find( '.modalImage' ).attr( 'dataIndex' ) );
        var newIndex = activeIndex + 1;
        if ( $( this ).hasClass( 'modalPrev' ) ) {
          newIndex = activeIndex - 1;
        }
        showModal.move( newIndex );
      } );
    };

    this.move = function( newIndex ) {
      if( newIndex == settings.src.length ) {
        newIndex = 0;
      } else if ( newIndex < 0 ) {
        newIndex = settings.src.length -1;
      }
      modal.find( '.modalImage' ).on( 'load', function() {
        showModal.setHeight();
      } ).attr( 'src', settings.src[ newIndex ].src).attr( 'dataIndex', newIndex );
      modal.find( '.modalCurrent' ).text( newIndex + 1 );
    };

    this.addContent = function() {
      if ( settings.type == 'video' && settings.src ) {
        modal.find( '.modalIframe' ).attr( 'src',settings.src );
        this.setHeight();
      }

      if ( settings.type === 'content' && settings.contentSelector !== false ) {
        var contentElement = $( settings.contentSelector ).clone( true, true ).css( 'display', 'block' );
        contentElement.appendTo( modal.find ('.modalContent' ) );
        this.setHeight();
      }

      if ( settings.type == 'img' && settings.src ) {
        modal.find( '.modalImage' ).on( 'load', function() {
          showModal.setHeight();
        } ).attr( 'src', settings.src );
      }

      if ( settings.type == 'gallery' && settings.src ) {
        modal.find( '.modalImage' ).on( 'load', function() {
          showModal.setHeight();
        } ).attr( 'src', settings.src[ 0 ].settings.src ).attr( 'dataIndex', 0 );
        modal.find( '.modalTotal' ).text( settings.src.length );
        modal.find( '.modalCurrent' ).text( 1 );
      }
    };

    this.dataCheck = function() {
      if( ! settings.type ) {
        alert( 'Contact the webmaster, no settings.type was added for this functionality.' );
        return false;
      }

      if ( ( settings.type == 'video' || settings.type == 'img' || settings.type == 'gallery' ) && settings.src === false ) {
        alert( 'Contact the webmaster, no source is specified.' );
        return false;
      }

      if ( settings.type == 'content' && settings.contentSelector === false ) {
        alert( 'Contact the webmaster, no content was added for this functionality.' );
        return false;
      }
    };

    this.modalCleanup = function() {
      if ( settings.type == 'video' && settings.src ) {
        modal.find( '.modalImage' ).remove();
        modal.find( '.modalNavigation' ).remove();
        modal.find( '.modalContent' ).remove();
      }

      if ( settings.type == 'content' && settings.contentSelector !== false ) {
        modal.find( '.modalIframe' ).remove();
        modal.find( '.modalNavigation' ).remove();
        modal.find( '.modalImage' ).remove();
      }

      if ( settings.type == 'img' && settings.src ) {
        modal.find( '.modalIframe' ).remove();
        modal.find( '.modalNavigation' ).remove();
        modal.find( '.modalContent' ).remove();
      }

      if ( settings.type == 'gallery' && settings.src ) {
        modal.find( '.modalIframe' ).remove();
        modal.find( '.modalContent' ).remove();
      }
    };

    this.setHeight = function() {
      modal.find( '.modalViewer, .modalImage, .modalContent, .modalIframe' ).removeAttr( 'style' );
      var contentHeight = modal.find( '.modalViewer' ).outerHeight();
      var contentWidth = modal.find( '.modalViewer' ).outerWidth();
      var maxWidth = Site.viewportWidth * 0.9;
      var maxHeight = Site.viewportHeight * 0.9;

      if ( contentWidth > maxWidth || contentHeight > maxHeight ) {
        var widthRatio = maxWidth / contentWidth;
        var heightRatio = maxHeight / contentHeight;
        var ratio = Math.min( widthRatio, heightRatio );

        if( contentWidth > maxWidth ) {
          modal.find( '.modalViewer' ).css( {
            'width': contentWidth * ratio,
          } );

          modal.find( '.modalImage, .modalContent, .modalIframe' ).css( {
            'max-width': '100%',
          } );
        } else if ( contentHeight > maxHeight ) {
          modal.find( '.modalViewer' ).css( {
            'height': contentHeight*ratio,
            'width': contentWidth*ratio,
          } );
          modal.find( '.modalImage, .modalContent, .modalIframe' ).css( {
            'overflow-y': 'auto',
            'max-height': '100%',
          } );
        }

        contentHeight = modal.find( '.modalViewer' ).outerHeight();
        contentWidth = modal.find( '.modalViewer' ).outerWidth();
      }

      modal.find( '.modalViewer' ).css( {
        'margin-top': -contentHeight/2,
        'margin-left': -contentWidth/2,
      } );
    };

    this.setup();
  },

  /**
   * Listener to show a video pop-up
   * Calls the Site.showModal function below.
   * Uses bootstrap-modal.js and override the defaults.
   *
   * @return null
   */
  showVideoPopup: function() {
    $( '.videoPopup, .js-video-popup' ).click( function( e ) {
      e.preventDefault();
      var $self = $( this );
      var videoSrc = $self.attr( 'href' );
      Site.showModal( {
        type: 'video',
        src: videoSrc,
      } );
    } );
  },






  initSlider: function() {
    $( '.slider' ).slick( {
      centerMode: true,
      variableWidth: true,
      slidesToShow: 1,
      slidesToScroll: 1,
    } );

    $( '.mobile-gallery' ).slick( {
      centerMode: true,
      variableWidth: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: '<button type="button" class="slick-prev mobile-gallery-prev">Previous</button>',
      nextArrow: '<button type="button" class="slick-next mobile-gallery-next">Next</button>',
    } );
  },

  initMobileSlider: function() {
    $( '.mobile-slider' ).slick( {
      arrows: false,
      dots: true,
    } );
  },



  setVideoModalEventHandlers: function() {
    var imageModal = $( '#imageModal' );
    var header = $( '#modal-heading' );
    var text = $( '#modal-text' );


    // run it on page load instead to prevent weird bugs
    $( '.modal-slider' ).slick( {
      adaptiveHeight: true,
    } );

    $( imageModal ).on( 'shown.bs.modal', function() {
      $( '.modal-slider' ).slick('refresh');
    } );


    $( imageModal ).on( 'hidden.bs.modal', function() {
      //$( '.modal-slider' ).slick( 'unslick' );
    } );

    $( '#close-modal' ).click( function() {
      $(".modal-backdrop").hide();
      $( '#imageModal' ).hide();
    } );
  },













  /*****************************************************************************************
   * Misc
   *****************************************************************************************/



  checkBreakpointLoaded: function() {
    for ( var breakpoint in Site.breakPoints ) {
      if ( Site.viewportWidth < Site.breakPoints[ breakpoint ].maxWidth ) {
        Site.currentView = breakpoint;
      }
    }
    if( $( '.ie8' ).length ) {
      Site.currentView = 'desktop';
    }
  },


  setViewportVariables: function() {
    Site.viewportWidth = $( window ).width();
    Site.viewportHeight = $( window ).height();
    Site.breakPoints = {
      desktop: {
        maxWidth: 1000000,
        next: undefined,
      },
      tabletLandscape: {
        maxWidth: 1024,
        next: 'desktop',
      },
      tablet: {
        maxWidth: 768,
        next: 'tabletLandscape',
      },
      mobileLandscape: {
        maxWidth: 568,
        next: 'tablet',
      },
      mobile: {
        maxWidth: 320,
        next: 'mobileLandscape',
      },
    };

    Site.currentView = 'desktop';
    Site.loadedView = 'desktop';
    for ( var breakpoint in Site.breakPoints ) {
      if ( Site.viewportWidth < Site.breakPoints[ breakpoint ].maxWidth ) {
        Site.currentView = breakpoint;
        Site.loadedView = breakpoint;
      }
    }
  },















};
