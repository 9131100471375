import moment from 'moment';
import {is_w1, is_w2} from '../global/functions';
import Cookie from "js-cookie";
import {initShowMoreDates,removeThirdCalendar} from './showMoreDates';

export function getMonthName(month) {
    const monthArray = new Array(
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
      );
    return monthArray[ ( month - 1 ) ];
}

export function twoMonthCalendar(ElemId, ElemId2, month, nextMonth, year, year2, weekOffset, dates, endDate, past, showprev) {
    let flag = false;
    let clickFlag = false;
    $("td").removeClass( 'promptToCall' );
    $("td").unbind('click','showPromptToCallMessageBox');
    $('.mobile-month-content').remove();
    
    if(global.calendarInfo == undefined){
      global.calendarInfo = {};
    }

    let datesArr = [];
  
    // Populate Appointment information with what we have so far
    const selectedCenter = global.ccUserInfo.availableCenters[global.ccUserInfo.centerID];
    global.ccUserInfo.addressString = `<h6 style="text-align:center">ClearChoice ${selectedCenter.name} Center</h6>`;
    global.ccUserInfo.addressString += `<p style="text-align:center; border-bottom:0; margin-bottom:0; padding-bottom:0;">${selectedCenter.address} `;
    global.ccUserInfo.addressString += `${selectedCenter.address2}<br/>`;
    global.ccUserInfo.addressString += `${selectedCenter.city}, ${selectedCenter.state} `;
    global.ccUserInfo.addressString += `${selectedCenter.zipcode}</p>`;
    // const addressHTML = global.ccUserInfo.addressString;
    $( '#confirmation-details .name' ).html( `${global.ccUserInfo.firstName} ${global.ccUserInfo.lastName}`);
    $( '#confirmation-details .date' ).html( '<span>Select a date on the calendar</span>' );
    $( '#confirmation-details .address' ).html( global.ccUserInfo.addressString );
  
    if( is_w1() ) {
      // Move term agreement and submit field into right column
      $( '.monthly-schedule-form .field-agree-to-terms' ).appendTo( '#webFormCalendar.cc-web-form-calendar-month .right-wrapper' );
      $( '.monthly-schedule-form .page-datetime-confirm .gform_page_footer' ).appendTo( '#webFormCalendar.cc-web-form-calendar-month .right-wrapper' );
      $( '.make-appointment' ).addClass( 'disabled-button' );
      if ( $( '#temp_submit_button' ).length === 0 ) {
        $( '.month-calendar-right .right-wrapper' ).append( '<input type="button" id="temp_submit_button" insp-form-input-id="submit-button" class="gform_next_button button" style="font-size:18px;" value="CONFIRM CONSULTATION" tabindex="21" >');
      }
    }
  
    const today = new Date();
    // const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    if ( typeof( weekOffset ) === 'undefined' || weekOffset === null ) {
      weekOffset = 0;
    }
    if ( (typeof( month ) === 'undefined' || month === null) ) {
    // sets month for first calendar -- left calendar
      month = today.getMonth() + 1;
    }
    if ( (typeof( nextMonth ) === 'undefined' || nextMonth === null) ) {
    // sets month for second calendar -- right calendar
      nextMonth = today.getMonth() + 2;
    }
  
    if ( typeof( year ) === 'undefined' || year === null ) {
      year = today.getFullYear(); 
    }

    if ( typeof( year2 ) === 'undefined' || year2 === null ) {
      year2 = today.getFullYear(); 
    }

    if ( typeof( dates ) === 'undefined' ) {
      datesArr = null;
    } else {
      for ( const key in dates ) {
        datesArr.push( dates[ key ].date );
      }
    }

    global.calendarInfo.dates = dates;
    global.calendarInfo.datesArr = datesArr;
   
    if ( typeof( past ) === 'undefined' ) {
      past = true;
    }
    
    // sets dates that will be used for populating templates for calendar left and calendar right
    const calendarDate = new Date( year, ( month - 1 ), 1, 0, 0, 0, 0 );
    const calendarTwoDate = new Date( year2, ( nextMonth - 1 ), 1, 0, 0, 0, 0 );
    const tempDate = new Date( year, ( month - 1), 1, 0, 0, 0, 0 );
    const tempTwoDate = new Date( year2, ( nextMonth - 1 ), 1, 0, 0, 0, 0 );

    // const lastDate = new Date( year, month, 0, 0, 0, 0, 0 );
    // gets the day of the week it is Sun-Sat (0-6)
    const day = calendarDate.getDay();
    const day2 = calendarTwoDate.getDay();
    
    
    // displays the dates on the calendar template 
    var stringDate = `${getMonthName( month )}, ${year}`;
    var stringDate2 = `${getMonthName( nextMonth )}, ${year2}`;
 
  
    global.dataLayer.push({ 'currentMonth': getMonthName( month )});
    global.dataLayer.push({ 'currentMonth2': getMonthName( nextMonth )});
    
    //set previous button to only render on left cal and next button to only render on right cal
    const oElem = $( `#${ElemId} table` );
    const oElem2 = $( `#${ElemId2} table` );
    const oButtons = $( `#${ElemId} a.btnPrev, #${ElemId2} a.btnNext` );
    // display current/next or last/current month on 2 month cal
    if ( past === false && ( year < today.getFullYear() || ( year === today.getFullYear() && month <= ( today.getMonth() + 1 ) ) ) ) {
      $( `#${ElemId} a[title="Last Month"]` ).hide();
      $( `#${ElemId2} a[title="Last Month"]` ).hide();
    } else {
      $( `#${ElemId2} a[title="Last Month"]` ).show();
      $( `#${ElemId} a[title="Last Month"]` ).show();
    }
    const oTd = oElem.find( 'td' );
    const oTd2 = oElem2.find( 'td' );
   
    // clear the tables to get it ready for appointment binding
    oTd.html( '&nbsp;' ).removeClass( 'currentMonth' ).removeClass( 'currentDay' ).removeClass( 'selected' ).removeClass( 'CalActive' );
    oTd2.html( '&nbsp;' ).removeClass( 'currentMonth' ).removeClass( 'currentDay' ).removeClass( 'selected' ).removeClass( 'CalActive' );
    const oH4 = $( `#${ElemId} .choose-date-month h4` );
    const oH4_2 = $( `#${ElemId2} .choose-date-month h4` );
  
    //add logic for doing this conditionally
    var navigationButtonTest = Cookie.get('test-navigation-button');
    if(navigationButtonTest != undefined && navigationButtonTest == 'true'){
      stringDate = `<span class="blue-month-name-calendar">${getMonthName( month )}</span></br><span class="year-string-calendar">${year}</span>`;
    }
    
    stringDate = `<span class="blue-month-name-calendar">${getMonthName( month )}</span></br><span class="year-string-calendar">${year}</span>`;
    stringDate2 = `<span class="blue-month-name-calendar">${getMonthName( nextMonth )}</span></br><span class="year-string-calendar">${year2}</span>`;

    // add the month and year to the calendar table objects 
    oH4.html( stringDate );
    oH4_2.html( stringDate2 );
    oElem.data( 'month', month );
    oElem.data( 'year', year );
    oElem2.data( 'month', nextMonth );
    oElem2.data( 'year', year2 );
  
  
    // bind event to select a list item 
    if ( oElem.data( 'boundEvents' ) === null || oElem.data( 'boundEvents' ) === undefined ) {
      oTd.bind( 'click', function() {
        if( $( this ).hasClass( 'CalActive' ) ) {
          oTd.removeClass( 'selected' );
          $( this ).addClass( 'selected' );
        }
      } );
      oElem.data( 'boundEvents', true );
    }
    if ( oElem2.data( 'boundEvents' ) === null || oElem2.data( 'boundEvents' ) === undefined ) {
      oTd2.bind( 'click', function() {
        if( $( this ).hasClass( 'CalActive' ) ) {
          oTd2.removeClass( 'selected' );
          $( this ).addClass( 'selected' );
        }
      } );
      oElem2.data( 'boundEvents', true );
    }
    oButtons.unbind( 'click' );
    
    let tmpThirdMonth = oElem2.data( 'month' );
    let tmpThirdYear = parseInt( oElem2.data( 'year' ) );
    tmpThirdMonth++;
    if ( tmpThirdMonth >= 13 ) {
      tmpThirdMonth = 1;
      tmpThirdYear++;
    }
    tmpThirdMonth = pad( tmpThirdMonth );

    global.calendarInfo.nextMonthToLoad = tmpThirdMonth;
    global.calendarInfo.nextYearToLoad = tmpThirdYear;
    //function to enable arrows 
    oButtons.bind( 'click', function() {
      // const _datesArr = datesArr;
      // use temporary variables to be able shift forward and back in the calendar templates
      // logic for forward and back arrows 
      let tmpMonth = oElem.data( 'month' );
      let tmpYear = parseInt( oElem.data( 'year' ) );
      let tmpMonth2 = oElem2.data( 'month' );
      let tmpYear2 = parseInt( oElem2.data( 'year' ) );
      if ( $( this ).hasClass( 'btnPrev' ) ) {
        tmpMonth--;
        tmpMonth2--;
        if ( tmpMonth <= 0 ) {
          tmpMonth = 12;
          tmpMonth2 = 1;
          tmpYear--;
        } else if (tmpMonth2 <= 0){
          tmpMonth = 11;
          tmpMonth2 = 12;
          tmpYear2--;
        }
        tmpMonth = pad( tmpMonth );
        tmpMonth2 = pad( tmpMonth2 );
        removeThirdCalendar();
        twoMonthCalendar( ElemId, ElemId2, tmpMonth, tmpMonth2, tmpYear, tmpYear2, 0, dates, endDate, past, false );
      } else {
        tmpMonth++;
        tmpMonth2++;
        if ( tmpMonth2 == 13 ) {
          tmpMonth2 = 1;
          tmpMonth = 12;
          tmpYear2++;
        } else if ( tmpMonth == 13) {
          tmpMonth2 = 2;
          tmpMonth = 1;
          tmpYear++;
        }
        tmpMonth = pad( tmpMonth );
        tmpMonth2 = pad( tmpMonth2 );
        removeThirdCalendar();
        twoMonthCalendar( ElemId, ElemId2, tmpMonth, tmpMonth2, tmpYear, tmpYear2, 0, dates, endDate, past, false );
      }
    } );


    //logic for displaying the next button or not
    //If month for end date is bigger than current month 2 of calendar or if month of enddate is
    //smaller but has a larger year show next button
    
    
   

    if((endDate.getMonth()+1 > parseInt(oElem2.data( 'month')) && endDate.getFullYear() == oElem2.data( 'year')) ||
    (endDate.getMonth()+1 < parseInt(oElem2.data( 'month')) && endDate.getFullYear() > oElem2.data( 'year'))){
      $( `#${ElemId2} a.btnNext` ).show();
      $('.moreDates').addClass('showButton');
      //push show next button dataLayer
      if($('.moreDates').css('display') == 'none' ){
        global.dataLayer.push({'event':'showNextButton'});
      } else{
        global.dataLayer.push({'event':'showMoreDates'});
      }
    }else{
      $( `#${ElemId2} a.btnNext` ).hide();
      $('.moreDates').removeClass('showButton');
    }

    //code for setting more dates functionality
    $('.showMoreDates').unbind( 'click' );
    $('.showMoreDates').click(function(){
      //push click more dates dataLayer
      global.dataLayer.push({'event':'clickShowMoreDates'});
      initShowMoreDates();
    });

    //push more dates dataLayer 
    $(window).bind("load resize",function(e){
      if($('.moreDates').css('display') != 'none' ){
        if(clickFlag == false){
          global.dataLayer.push({'event':'showMoreDates'});
          clickFlag = true;
        }
      }else if($('.btnNext').css('display') != 'none'){
        global.dataLayer.push({'event':'showNextButton'});
      }else if($('.btnPrev').css('display') != 'none'){
        global.dataLayer.push({'event':'showPrevButton'});
      }
    });
    

    let n = 0;
    // set the date that will iterate in the while loop with each run
    tempDate.setDate( 0 - day );
   
    //while loop to populate dates in the first or left calendar 
    while( n < oTd.length ) {
     
      tempDate.setDate( tempDate.getDate() + 1 );
  
      oTd.eq( n ).html( tempDate.getDate() );
  
      const yearString = tempDate.getFullYear();
      oTd.eq( n ).data( 'year',yearString );
    
      let monthString = tempDate.getMonth() + 1;
      monthString = `00${monthString}`;
      monthString = monthString.substring( monthString.length - 2, monthString.length );
      oTd.eq( n ).data( 'month', monthString );
  
      let dateString = tempDate.getDate();
      dateString = `00${dateString}`;
      dateString = dateString.substring( dateString.length - 2, dateString.length );
  
      // Set <td> 'data-day'
      oTd.eq( n ).data( 'day', dateString );
  
      // Adjust dateString
      dateString = `${yearString}-${monthString}-${dateString}`;
  
      // Reset TD classes
      oTd.eq( n ).addClass('currentMonth').removeClass( 'CalFull' );
  
      // Test Case Specific - slides up the time selector
      oTd.eq( n ).removeClass( 'inactiveMonth' );
  
      if( is_w1() ) {
          $('#webFormCalendar .choose-date-timelist').slideUp();
      }
  
      if ( tempDate.getMonth() == calendarDate.getMonth() ) {
        oTd.eq( n ).addClass( 'currentMonth' );
  
        if ( datesArr === null ) {
          oTd.eq( n ).addClass( 'CalActive' );
        } else if( $.inArray( dateString, datesArr ) >= 0 ) {
          oTd.eq( n ).addClass( 'CalActive' );
        }
  
        //Add "full" class to full days
        const dateStr = dateString.replace( /-/g, '/');
  
        if ( typeof dates[ dateStr ] != 'undefined' ) {
  
          // Checking for same-day scheduling
          if ( tempDate.getDate() === today.getDate() ) {
            let todayFull = true;
            
            if ( global.ccUserInfo.marketScore === 'GYIM' || global.ccUserInfo.leadSegment == 4 || global.ccUserInfo.leadSegment == 5) {
              const apps = dates[ dateStr ].appointmentSlots;
              // build out calendar appts for time 
              for ( let i = apps.length - 1; i >= 0; i-- ) {
                // Building moment.js string from appt date/time
                const time = apps[i].start;
  
                const wholeTime = time.replace( ' ', '.0' );
                const thisDay = apps[i].date;
                const finalDate = `${thisDay} ${wholeTime}`;
  
                // Appointment is more than 2 hours away & has a block (it's available)
                if ( ( moment( finalDate ) - moment( today ) > 7200000 ) && apps[i].blocks.length ) {
  
                  // Set flag to false ( so we don't "block" off today );
                  todayFull = false;
                } else {
  
                  // Set block to "empty/scheduled"
                  apps[i].blocks = [];
                }
              }
            }
  
            // If todayFull is still true ( no appts match "more than 2 hours in the future" AND "still open in SF" )
            if ( todayFull ) {
              oTd.eq( n ).removeClass( 'CalActive' ).addClass( 'CalFull' );
            }
          }
          // End Same-Day Schedule adjustments
  
          // If day is full:
          if ( dates[ dateStr ].full ) {
            oTd.eq( n ).removeClass( 'CalActive' ).addClass( 'CalFull' );
          }
  
          if ( dates[ dateStr ].promptToCall != undefined &&  dates[ dateStr ].promptToCall == true) {
            oTd.eq( n ).addClass('promptToCall');
          }
        }
  
      } else {
        oTd.eq( n ).addClass( 'inactiveMonth' );
      }
      // add current day display to first calendar
      if( tempDate.getMonth() == today.getMonth() && tempDate.getDate() == today.getDate() && tempDate.getFullYear() == today.getFullYear()) {
        oTd.eq( n ).addClass( 'currentDay' );
      }
    
      
      if( tempDate.getMonth() == endDate.getMonth() && tempDate.getDate() == endDate.getDate() ) {
        oTd.eq( n ).addClass( 'endDay' );
      }
     
      n++;
    }

    if( $('#cal-left-wrap td.currentDay').is(':visible') ) {
      $( `#${ElemId} a.btnPrev` ).hide();
    } else {
      $( `#${ElemId} a.btnPrev` ).show();
      //push show prev button dataLayer
      if(flag == false){
      global.dataLayer.push({'event':'showPrevButton'});
      flag = true;
      }
    }
    // loop to populate dates for second or right calendar 
    let m = 0;
    tempTwoDate.setDate( 0 - day2 );
    //while loop to populate the second calendar
    while( m < oTd2.length ) {
  
      tempTwoDate.setDate( tempTwoDate.getDate() + 1 );
      oTd2.eq( m ).html( tempTwoDate.getDate() );
  
      const yearStringTwo = tempTwoDate.getFullYear();
      oTd2.eq( m ).data( 'year',yearStringTwo );
  
      let monthStringTwo = tempTwoDate.getMonth() + 1;
      monthStringTwo = `00${monthStringTwo}`;
      monthStringTwo = monthStringTwo.substring( monthStringTwo.length - 2, monthStringTwo.length );

      oTd2.eq( m ).data( 'nextMonth', monthStringTwo );
  
      let dateStringTwo = tempTwoDate.getDate();
      dateStringTwo = `00${dateStringTwo}`;
      dateStringTwo = dateStringTwo.substring( dateStringTwo.length - 2, dateStringTwo.length );
  
      // Set <td> 'data-day'
      oTd2.eq( m ).data( 'day', dateStringTwo);
  
      // Adjust dateString
      dateStringTwo = `${yearStringTwo}-${monthStringTwo}-${dateStringTwo}`;
   
      // Reset TD classes
      oTd2.eq( m ).addClass('currentMonth').removeClass( 'CalFull' );
  
      // Test Case Specific - slides up the time selector
      oTd2.eq( m ).removeClass( 'inactiveMonth' );
  
      if( is_w1() ) {
          $('#webFormCalendar3 .choose-date-timelist').slideUp();
      }
  
      if ( tempTwoDate.getMonth() == calendarTwoDate.getMonth() ) {
        oTd2.eq( m ).addClass( 'currentMonth' );
  
        if ( datesArr === null ) {
          oTd2.eq( m ).addClass( 'CalActive' );
        } else if( $.inArray( dateStringTwo, datesArr ) >= 0 ) {
          oTd2.eq( m ).addClass( 'CalActive' );
        }
  
        //Add "full" class to full days
        const dateStrTwo = dateStringTwo.replace( /-/g, '/');
        
        if ( typeof dates[ dateStrTwo ] != 'undefined' ) {
  
          // Checking for same-day scheduling
          if ( tempTwoDate.getDate() === today.getDate() ) {
            let todayFull2 = true;
  
            if ( global.ccUserInfo.marketScore === 'GYIM' || global.ccUserInfo.leadSegment == 4 || global.ccUserInfo.leadSegment == 5) {
              const apps = dates[ dateStrTwo ].appointmentSlots;
  
              for ( let i = apps.length - 1; i >= 0; i-- ) {
                // Building moment.js string from appt date/time
                const time = apps[i].start;
  
                const wholeTime = time.replace( ' ', '.0' );
                const thisDay = apps[i].date;
                const finalDate = `${thisDay} ${wholeTime}`;
  
                // Appointment is more than 2 hours away & has a block (it's available)
                if ( ( moment( finalDate ) - moment( today ) > 7200000 ) && apps[i].blocks.length ) {
  
                  // Set flag to false ( so we don't "block" off today );
                  todayFull2 = false;
                } else {
  
                  // Set block to "empty/scheduled"
                  apps[i].blocks = [];
                }
              }
            }
  
            // If todayFull is still true ( no appts match "more than 2 hours in the future" AND "still open in SF" )
            if ( todayFull2 ) {
              oTd2.eq( m ).removeClass( 'CalActive' ).addClass( 'CalFull' );
            }
          }
          // End Same-Day Schedule adjustments
  
          // If day is full:
          if ( dates[ dateStrTwo ].full ) {
            oTd2.eq( m ).removeClass( 'CalActive' ).addClass( 'CalFull' );
          }
  
          if ( dates[ dateStrTwo ].promptToCall != undefined &&  dates[ dateStrTwo ].promptToCall == true) {
            oTd2.eq( m ).addClass('promptToCall');
          }
        }
  
      } else {
        oTd2.eq( m ).addClass( 'inactiveMonth' );
      }

      // show next buttom if the second calendar is the current month 
      if( (tempTwoDate.getMonth() == today.getMonth() && tempTwoDate.getDate() == today.getDate()) && tempTwoDate.getFullYear() == today.getFullYear()) {
         oTd2.eq( m ).addClass( 'currentDay' );
      }
  
      if( tempTwoDate.getMonth() == endDate.getMonth() && tempTwoDate.getDate() == endDate.getDate() ) {
      oTd2.eq( m ).addClass( 'endDay' );
      }
  
        // Only hide next button if the endDate isn't also in an inactive month row (extra row that will be hidden if it's all "inactiveMonth" class)
      //   if( ! oTd2.eq(m).hasClass( 'inactiveMonth' ) ) {
      //     $( `#${ElemId2} a.btnNext` ).hide();
      //   }
      // }
      m++;
    }
    // force show previous if passed in the calendar 
    if(showprev){
      $( `#${ElemId} a.btnPrev` ).show();
      //push show prev button dataLayer
      global.dataLayer.push({'event':'showPrevButton'});
    }
  
    // Hide the last <tr> if all of the dates in the row are ".inactiveMonth"
    oElem.find( 'tr' ).each( function() {
      if ( $( this ).find( 'td.inactiveMonth' ).length === 7 ) {
        $( this ).hide();
      } else {
        $( this ).show();
      }
    } );

    oElem2.find( 'tr' ).each( function() {
      if ( $( this ).find( 'td.inactiveMonth' ).length === 7 ) {
        $( this ).hide();
      } else {
        $( this ).show();
      }
    } );
  }

// padd to return proper date in the month
function pad( n ) {
  if ( n < 10 ) {
    return `0${n}`;
  } else {
    return n;
  }
}
  