// add autocomplete list menu to the address input 
export function initMenu(){
	$( '.field-address input').after('<ul class="us-autocomplete-pro-menu" style="display:none;"></ul>');
	var menu = $(".us-autocomplete-pro-menu");
	var input = $(".field-address input");
	$('.field-address input').attr('autocomplete', 'new-password');
}

// address autocomplete smarty streets 
export function getSuggestions(search) {
	let myAuthId;
	if($( ".gform_body").length>0){
		if($( "#input_19_42").val() == undefined){
			myAuthId = $( "#input_20_39").val();
		}else if($( "#input_20_39").val() == undefined){
			myAuthId = $( "#input_19_42").val();
		}else{ 
			console.log("SmartyStreets cannot authenticate.");
		}
	}
		var addressVal = search;
		var addressData = {};
		addressData.onKeyAddress = addressVal;
		//addressData.action = "cc_send_address_to_frontend";
		$.ajax({
			url: "https://us-autocomplete-pro.api.smartystreets.com/lookup?",
			data: {
				"auth-id": myAuthId,
				"search": addressVal,
				"max_results": 3,
			},
			dataType: "jsonp",
			success: function(data) {
				if(data){
					$(".us-autocomplete-pro-menu").empty();
					if(data.suggestions == null || data.suggestions == undefined){
						noSuggestions();
					}else{
						var size = Object.size(data.suggestions);
						if(size <= 3){
							$(".us-autocomplete-pro-menu").css("height","auto");
						}
						buildMenu(data.suggestions);
						$( ".us-autocomplete-pro-menu > li").click(function() {
							if(($(this)[0].innerText.includes("more entries"))){
								var address = $(this)[0].childNodes[0].dataset.address.split(";");
								$("#us-autocomplete-pro-address-input").val(address[0] + " ");
								address.splice(1,0, "*");
								address = address.join(" ");
								getSuggestions(address);
								$(".us-autocomplete-pro-menu").css("height","200px");
							}else{
								$(".us-autocomplete-pro-menu").css("height","auto");
								var addressForAutofill = $(this)[0].childNodes[0].dataset.address.split(";");
								$(".us-autocomplete-pro-menu").empty();
								$("#mobile-form .us-autocomplete-pro-menu").empty();
								$("#input_19_4").val(addressForAutofill[0]);
								$("#input_19_5").val(addressForAutofill[3]);
								$("#input_19_6").val(addressForAutofill[1]);
								$("#input_19_41").val(addressForAutofill[2]);
								if($("#mobile-form").is(":visible")){
									// partial Mobile
									$("#mobile-form #input_20_4").val(addressForAutofill[0]);
									$("#mobile-form #input_20_5").val(addressForAutofill[3]);
									$("#mobile-form #input_20_6").val(addressForAutofill[1]);
									$("#mobile-form #input_20_38").val(addressForAutofill[2]);
								} else {
									// partial Desktop
									$("#input_20_4").val(addressForAutofill[0]);
									$("#input_20_5").val(addressForAutofill[3]);
									$("#input_20_6").val(addressForAutofill[1]);
									$("#input_20_38").val(addressForAutofill[2]);
								}
							}	 
						});
				}   
				} else {
					noSuggestions();
				}
			},
			error : function(req, err) {
				console.log('Error:' + err);
			}, 
		});
}

// function to clear an address
function clearAddressData() {
	$(".field-address input").val("");
}

Object.size = function(obj) {
	var size = 0,
	key;
	for (key in obj) {
		if (obj.hasOwnProperty(key)) size++;
	}
	return size;
};

// default display for No Suggestion Found 
function noSuggestions() {
	var menu = $(".us-autocomplete-pro-menu");
	menu.empty();
	$(".us-autocomplete-pro-menu").css("height","auto");
	menu.append("<li class='autocomplete-address-items'><div>No Suggestions Found</div></li>");
	$( ".us-autocomplete-pro-menu > li").click(function() {
		menu.empty();
	});
	setTimeout(function(){
		$('.autocomplete-address-items').remove();
	}, 5000);
}

// function that builds out the address and checks if multiple entries are returned for a condo/unit
function buildAddress(suggestion) {
	var whiteSpace = "";
	if (suggestion.secondary || suggestion.entries > 1) {
		if (suggestion.entries > 1) {
			suggestion.secondary += " (" + suggestion.entries + " more entries)";
		}
		whiteSpace = " ";
	}
		var address = suggestion.street_line + whiteSpace + suggestion.secondary + " " + suggestion.city + ", " + suggestion.state +  ", "  + suggestion.zipcode;
	var inputAddress = $(".field-address input").val();
	for (var i = 0; i < address.length; i++) {
		var theLettersMatch = typeof inputAddress[i] == "undefined" || address[i].toLowerCase() !== inputAddress[i].toLowerCase();
		if (theLettersMatch){
			address = [address.slice(0,i), "<b>", address.slice(i)].join("");
			break;
		}
	}
	return address;
}

// builds the individual list items(addresses) and appends them to the address menu 
function buildMenu(suggestions) {
	var menu = $(".us-autocomplete-pro-menu");
	suggestions.map(function (suggestion) {
		var caret = (suggestion.entries > 1 ? "<span class=\"ui-menu-icon ui-icon ui-icon-caret-1-e\"></span>" : "");
		menu.append("<li class='autocomplete-address-items'><div data-address='" +
			suggestion.street_line + (suggestion.secondary ? " " + suggestion.secondary : "") + ";" +
			suggestion.city + ";" +
			suggestion.state + ";" +
			suggestion.zipcode + "'>" +
			caret +
			buildAddress(suggestion) + "</b></div></li>");
	});
}