import Cookie from 'js-cookie';
import {processAppointments} from './processAppointments';
import {flagGYIM} from './flagGYIM';
import {setGlobalGAInfo,showMessageBox,hideMessageBox,showCallMeNow,loadCallMeNowForm,schedulingDisabledPopup,initDateTimeField} from './formFunctions';
import queryString from 'query-string';
import {showWaitingIcon,hideWaitingIcon} from './w1_helper_functions';
import {saveCampaign,showTextMessageTermsBox,showTextConsentCheckboxTest,createUuid} from './formFunctions';

export function saveLeadInfo() {
  global.saveLeadInfoLock = new $.Deferred();
  let callCenterNumber = $('.trans_id').first().text() || '888-651-9950';
  // console.log(callingelement)
  // If this is the first time going through this function,
  // define a flag so we know if it's been repeated
  if (typeof global.ccUserInfo.attemptsAtInitAjax === 'undefined') {
    global.ccUserInfo.attemptsAtInitAjax = 0;
  }

  // Step up the variable
  global.ccUserInfo.attemptsAtInitAjax++;

  let leadData = getLeadData();

  leadData.action = 'cc_salesforce_save_lead';
  /* eslint-disable */
  let shouldContinue;
  /* eslint-enable */

  if (global.ccUserInfo.attemptsAtInitAjax === 1) {
    $.ajax({
      type: 'POST',
      url: ajaxurl,
      dataType: 'json',
      contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
      data: leadData,
      cache: false,
      headers: { "cache-control": "no-cache" },
      success: function(msg) {
        console.log('Success');
        console.log(msg);
        shouldContinue = true;
        let retrievedGUID;
        if(msg.errorMessage != undefined ){
          if(msg.errorMessage == 'update error'){
            global.ccUserInfo.retryId = msg.retryId;
          }
          global.ccUserInfo.retryCall = true;
          saveLeadInfo();
        }
        if (msg.totalSize > 0) {
          retrievedGUID = msg.records[0].Id;
          Cookie.set('GUID', window.btoa(retrievedGUID));
          Cookie.set('userId', retrievedGUID);
          global.ccUserInfo.guid = retrievedGUID;
          global.ccUserInfo.leadScore = msg.leadScore;
          global.ccUserInfo.sf_gy = msg.sf_gy;
          // ga('set','userId',retrievedGUID);
          global.dataLayer.push({ 'User Id DataLayer': retrievedGUID});
          global.dataLayer.push({ 'salesforce_guid': retrievedGUID,
          'UserId':retrievedGUID});
          if (msg.records[0].IsConverted) {
            global.ccUserInfo.contactID = msg.records[0].ConvertedContactId;
            global.ccUserInfo.accountID = msg.records[0].ConvertedAccountId;
          }
          global.ccUserInfo.appointmentScheduled = msg.records[0].Appointment_Scheduled__c;
          global.ccUserInfo.consultInventory = msg.records[0].Consult_Inventory__r;

          global.saveLeadInfoLock.resolve(true);
          global.saveLeadInfoDone = true;
          //temporary code for campaign
          var checkForSmarty = Cookie.get('test-smarty-streets');
          if((!checkForSmarty || typeof checkForSmarty == 'undefined')){
            //no address autocompete
          }else{
            saveCampaign('SmartyStreets');
          }
          saveLeadAdditionalInfo();

        } else {
          if (typeof msg !== 'undefined' && typeof msg.success !== 'undefined' && msg.success === true) {
            retrievedGUID = msg.id;
            Cookie.set('GUID', window.btoa(retrievedGUID));
            Cookie.set('userId', retrievedGUID);
            global.dataLayer.push({ 'User Id DataLayer': retrievedGUID});
            global.dataLayer.push({ 'salesforce_guid': retrievedGUID, 'UserId':retrievedGUID});
            global.ccUserInfo.guid = retrievedGUID;

            ccUserInfo.sf_gy = msg.sf_gy;
            global.ccUserInfo.leadScore = msg.leadScore;

            global.saveLeadInfoLock.resolve(true);
            global.saveLeadInfoDone = true;
             //temporary code for campaign
            var checkForSmarty2 = Cookie.get('test-smarty-streets');
            if((!checkForSmarty2 || typeof checkForSmarty2 == 'undefined')){
              //no address autocompete
            }else{
              saveCampaign('SmartyStreets');
            }
            saveLeadAdditionalInfo();
          } else {
            var flag = false;

            if (typeof msg[0] !== 'undefined' &&
              (typeof msg[0].errors[0] !== 'undefined') &&
              (typeof msg[0].errors[0].statusCode !== 'undefined') &&
              (msg[0].errors[0].statusCode === 'INVALID_EMAIL_ADDRESS')
            ) {
              $('#webform-messages').html('<div class="alert alert-danger" role="alert">That doesn\'t seem to be a valid email address. Please correct, or call ' + callCenterNumber + ' for assistance.</div>');
              flag = true;
            } else if (global.ccUserInfo.attemptsAtInitAjax < 2) {
              saveLeadInfo('');
            } else {
              $('#webform-messages').html('<div class="alert alert-danger" role="alert">There was an issue starting the scheduling process. Please try again later, or call ' + callCenterNumber + ' for assistance.</div>');
              flag = true;
              global.saveLeadInfoLock.resolve(false);
              global.saveLeadInfoDone = false;
            }

            if (flag) {
              global.dataLayer.push({ "error_data": msg });
              global.dataLayer.push({ "error_message": "Server Error looking up/ creating lead info" });
              global.dataLayer.push({ "event": "ajax-error" });
              shouldContinue = false;
            }
          }
        }
        if(msg == "failed to save lead" || msg == "failed"){
          $('#webform-messages').html('<div class="alert alert-danger" role="alert">There was an issue connecting to our servers. Please try again later, or call ' + callCenterNumber + ' for assistance.</div>');
        }
      },
      error: function(result) {
        // If the ajax fails for some reason, try again
        if (global.ccUserInfo.attemptsAtInitAjax < 2) {
          saveLeadInfo('');
        } else {
          global.saveLeadInfoLock.resolve(false);
          global.saveLeadInfoDone = false;
          $('#webform-messages').html('<div class="alert alert-danger" role="alert">There was an issue connecting to our servers. Please try again later, or call ' + callCenterNumber + ' for assistance.</div>');

          global.dataLayer.push({ "error_data": result });
          global.dataLayer.push({ "error_message": "Ajax Error looking up/ creating lead info" });
          global.dataLayer.push({ "event": "ajax-error" });
        }
      },
    });
  }else{
    global.saveLeadInfoLock.resolve(false);
    global.saveLeadInfoDone = false;
    $('#webform-messages').html('<div class="alert alert-danger" role="alert">There was an issue connecting to our servers. Please try again later, or call ' + callCenterNumber + ' for assistance.</div>');

    global.dataLayer.push({ "error_data": 'failedRetry' });
    global.dataLayer.push({ "error_message": "Ajax Error looking up/ creating lead info" });
    global.dataLayer.push({ "event": "ajax-error" });
  }

}

export function saveUpdateLeadInfoAndRetrieveInfo() {
  global.saveLeadInfoLock = new $.Deferred();
  let callCenterNumber = $('.trans_id').first().text() || '888-651-9950';

  if (typeof global.ccUserInfo.attemptsAtInitAjax === 'undefined') {
    global.ccUserInfo.attemptsAtInitAjax = 0;
  }

  // set up the variable
  global.ccUserInfo.attemptsAtInitAjax++;

  var leadData = {};
  leadData.leadId = global.ccUserInfo.retrievedLeadId;
  leadData.newLead = global.ccUserInfo.newLead;
  leadData.FewQuestions = global.ccUserInfo.fewQuestions;
  leadData.securityToken = global.securityToken;
  leadData.action = 'cc_salesforce_personal_info_step_update_lead_info';
  /* eslint-disable */
  let shouldContinue;
  /* eslint-enable */

  if (global.ccUserInfo.attemptsAtInitAjax === 1) {
    $.ajax({
      type: 'POST',
      url: ajaxurl,
      dataType: 'json',
      contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
      data: leadData,
      cache: false,
      headers: { "cache-control": "no-cache" },
      success: function(msg) {
        console.log(msg);
        shouldContinue = true;
        let retrievedGUID;
        if (msg.totalSize > 0) {
          retrievedGUID = msg.records[0].Id;
          Cookie.set('GUID', window.btoa(retrievedGUID));
          Cookie.set('userId', retrievedGUID);
          global.ccUserInfo.guid = retrievedGUID;
          global.ccUserInfo.leadScore = msg.leadScore;
          global.ccUserInfo.sf_gy = msg.sf_gy;
          // ga('set','userId',retrievedGUID);
          global.dataLayer.push({ 'User Id DataLayer': retrievedGUID});
          global.dataLayer.push({ 'salesforce_guid': retrievedGUID,
          'UserId':retrievedGUID});
          if (msg.records[0].IsConverted) {
            global.ccUserInfo.contactID = msg.records[0].ConvertedContactId;
            global.ccUserInfo.accountID = msg.records[0].ConvertedAccountId;
          }
          global.ccUserInfo.appointmentScheduled = msg.records[0].Appointment_Scheduled__c;
          global.ccUserInfo.consultInventory = msg.records[0].Consult_Inventory__r;

          global.saveLeadInfoLock.resolve(true);
          Cookie.remove('cc-pre-form');
          Cookie.remove('userFrontEndId');
          global.saveLeadInfoDone = true;
           //temporary code for campaign
           var checkForSmarty3 = Cookie.get('test-smarty-streets');
           if((!checkForSmarty3 || typeof checkForSmarty3 == 'undefined')){
             //no address autocompete
           }else{
             saveCampaign('SmartyStreets');
           }
          saveLeadAdditionalInfo();

        }else {
          var flag = false;

          if (typeof msg[0] !== 'undefined' &&
            (typeof msg[0].errors[0] !== 'undefined') &&
            (typeof msg[0].errors[0].statusCode !== 'undefined') &&
            (msg[0].errors[0].statusCode === 'INVALID_EMAIL_ADDRESS')
          ) {
            $('#webform-messages').html('<div class="alert alert-danger" role="alert">That doesn\'t seem to be a valid email address. Please correct, or call ' + callCenterNumber + ' for assistance.</div>');
            flag = true;
          } else if (global.ccUserInfo.attemptsAtInitAjax < 2) {
            saveUpdateLeadInfoAndRetrieveInfo('');
          } else {
            $('#webform-messages').html('<div class="alert alert-danger" role="alert">There was an issue starting the scheduling process. Please try again later, or call ' + callCenterNumber + ' for assistance.</div>');
            flag = true;
            global.saveLeadInfoLock.resolve(false);
            global.saveLeadInfoDone = false;
            Cookie.remove('cc-pre-form');
            Cookie.remove('userFrontEndId');
          }

          if (flag) {
            global.dataLayer.push({ "error_data": msg });
            global.dataLayer.push({ "error_message": "Server Error looking up/ creating lead info" });
            global.dataLayer.push({ "event": "ajax-error" });
            shouldContinue = false;
          }
        }
        
        if(msg == "failed to save lead" || msg == "failed"){
          $('#webform-messages').html('<div class="alert alert-danger" role="alert">There was an issue connecting to our servers. Please try again later, or call ' + callCenterNumber + ' for assistance.</div>');
        }
      },
      error: function(result) {
        // If the ajax fails for some reason, try again
        if (global.ccUserInfo.attemptsAtInitAjax < 2) {
          saveUpdateLeadInfoAndRetrieveInfo('');
        } else {
          global.saveLeadInfoLock.resolve(false);
          global.saveLeadInfoDone = false;
          Cookie.remove('cc-pre-form');
          Cookie.remove('userFrontEndId');
          $('#webform-messages').html('<div class="alert alert-danger" role="alert">There was an issue connecting to our servers. Please try again later, or call ' + callCenterNumber + ' for assistance.</div>');

          global.dataLayer.push({ "error_data": result });
          global.dataLayer.push({ "error_message": "Ajax Error looking up/ creating lead info" });
          global.dataLayer.push({ "event": "ajax-error" });
        }
      },
    });
  }

}

export function saveLeadInfoPartial() {
  global.saveLeadInfoLock = new $.Deferred();
  let callCenterNumber = $('.trans_id').first().text() || '888-651-9950';
  // console.log(callingelement)
  // If this is the first time going through this function,
  // define a flag so we know if it's been repeated
  if (typeof global.ccUserInfo.attemptsAtInitAjax === 'undefined') {
    global.ccUserInfo.attemptsAtInitAjax = 0;
  }

  // Step up the variable
  global.ccUserInfo.attemptsAtInitAjax++;

  let leadData = getLeadData();
  leadData.isPartialForm = true;
  leadData.userLeadReserveFrontEndUid = Cookie.get('userFrontEndId');
  console.log(leadData);
  console.log(Cookie.get('userFrontEndId'));
  leadData.action = 'cc_salesforce_save_lead_partial';
  /* eslint-disable */
  let shouldContinue;
  /* eslint-enable */

  if (global.ccUserInfo.attemptsAtInitAjax === 1) {
    $.ajax({
      type: 'POST',
      url: ajaxurl,
      dataType: 'json',
      contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
      data: leadData,
      cache: false,
      headers: { "cache-control": "no-cache" },
      success: function(msg) {
        shouldContinue = true;
        let retrievedGUID;
        if (msg.size > 0) {
          retrievedGUID = msg.records[0].Id;
          Cookie.set('GUID', window.btoa(retrievedGUID));
          Cookie.set('userId', retrievedGUID);
          global.ccUserInfo.guid = retrievedGUID;

          global.ccUserInfo.sf_gy = msg.records[0].sf_gy
          // ga('set','userId',retrievedGUID);
          global.dataLayer.push({ 'User Id DataLayer': retrievedGUID});
          global.dataLayer.push({ 'salesforce_guid': retrievedGUID,
          'UserId':retrievedGUID});
          if (msg.records[0].IsConverted) {
            global.ccUserInfo.contactID = msg.records[0].ConvertedContactId;
            global.ccUserInfo.accountID = msg.records[0].ConvertedAccountId;
          }
          global.ccUserInfo.appointmentScheduled = msg.records[0].Appointment_Scheduled__c;
          global.ccUserInfo.consultInventory = msg.records[0].Consult_Inventory__r;

          global.saveLeadInfoLock.resolve(true);
          global.saveLeadInfoDone = true;
          saveLeadAdditionalInfo();

        } else {
          if (typeof msg[0] !== 'undefined' && typeof msg[0].success !== 'undefined' && msg[0].success === true) {
            retrievedGUID = msg[0].id;
            Cookie.set('GUID', window.btoa(retrievedGUID));
            Cookie.set('userId', retrievedGUID);
            global.dataLayer.push({ 'User Id DataLayer': retrievedGUID});
            global.dataLayer.push({ 'salesforce_guid': retrievedGUID, 'UserId':retrievedGUID});
            global.ccUserInfo.guid = retrievedGUID;

            ccUserInfo.sf_gy = msg[0].sf_gy;
            
            global.saveLeadInfoLock.resolve(true);
            global.saveLeadInfoDone = true;
            saveLeadAdditionalInfo();
          } else {
            var flag = false;

            if (typeof msg[0] !== 'undefined' &&
              (typeof msg[0].errors[0] !== 'undefined') &&
              (typeof msg[0].errors[0].statusCode !== 'undefined') &&
              (msg[0].errors[0].statusCode === 'INVALID_EMAIL_ADDRESS')
            ) {
              $('#webform-messages').html('<div class="alert alert-danger" role="alert">That doesn\'t seem to be a valid email address. Please correct, or call ' + callCenterNumber + ' for assistance.</div>');
              flag = true;
            } else if (global.ccUserInfo.attemptsAtInitAjax < 2) {
              saveLeadInfo('');
            } else {
              $('#webform-messages').html('<div class="alert alert-danger" role="alert">There was an issue starting the scheduling process. Please try again later, or call ' + callCenterNumber + ' for assistance.</div>');
              flag = true;
              global.saveLeadInfoLock.resolve(false);
              global.saveLeadInfoDone = false;
            }

            if (flag) {
              global.dataLayer.push({ "error_data": msg });
              global.dataLayer.push({ "error_message": "Server Error looking up/ creating lead info" });
              global.dataLayer.push({ "event": "ajax-error" });
              shouldContinue = false;
            }
          }
        }
        if(msg == "failed to save lead" || msg == "failed"){
          $('#webform-messages').html('<div class="alert alert-danger" role="alert">There was an issue connecting to our servers. Please try again later, or call ' + callCenterNumber + ' for assistance.</div>');
        }
      },
      error: function(result) {
        // If the ajax fails for some reason, try again
        if (global.ccUserInfo.attemptsAtInitAjax < 2) {
          saveLeadInfo('');
        } else {
          global.saveLeadInfoLock.resolve(false);
          global.saveLeadInfoDone = false;
          $('#webform-messages').html('<div class="alert alert-danger" role="alert">There was an issue connecting to our servers. Please try again later, or call ' + callCenterNumber + ' for assistance.</div>');

          global.dataLayer.push({ "error_data": result });
          global.dataLayer.push({ "error_message": "Ajax Error looking up/ creating lead info" });
          global.dataLayer.push({ "event": "ajax-error" });
        }
      },
    });
  }

}

export function saveLeadAdditionalInfo() {
  // If this is the first time going through this function,
  // define a flag so we know if it's been repeated
  if (typeof global.ccUserInfo.attemptsAtInfoAjax === 'undefined') {
    global.ccUserInfo.attemptsAtInfoAjax = 0;
  }

  // Step up the variable
  global.ccUserInfo.attemptsAtInfoAjax++;

  /* eslint-disable */
  let shouldContinue;
  /* eslint-enable */

  let leadData = getLeadData();

  leadData.action = 'cc_salesforce_save_lead_additional_info';

  if (global.ccUserInfo.attemptsAtInfoAjax === 1) {
    $.ajax({
        type: 'POST',
        url: ajaxurl,
        dataType: 'json',
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        data: leadData,
        cache: false,
        headers: { "cache-control": "no-cache" },
        success: function() {
        },
        error: function(result) {
          if (global.ccUserInfo.attemptsAtInfoAjax < 2) {
            saveLeadAdditionalInfo('');
          } else {
            global.dataLayer.push({ "error_data": result });
            global.dataLayer.push({ "error_message": "Ajax Error saving additional info to lead" });
            global.dataLayer.push({ "event": "ajax-error" });
          }
        },
      });
  }
}

export function retrieveAppointments() {
  //var testTextConfirmationCheckBox = Cookie.get('testTextConfirmationCheckBox');
  //var testTextConfirmationPopUp = Cookie.get('testTextConfirmationPopUp');
  //if(testTextConfirmationCheckBox && !testTextConfirmationPopUp){
    //saveCampaign('Text_v1');
    //showTextConsentCheckboxTest();
  //}else if(!testTextConfirmationCheckBox && testTextConfirmationPopUp){
    //var agreeText = "<p style'text-align:center;'>By confirming your consult, you agree to the <a href='/terms/' target='_blank'>terms and conditions</a> and that the individual is over the age of 18.</p>"
    //$('.field-agree-to-terms').append(agreeText);
    //$('#input_19_17').hide();
  //}
  showWaitingIcon('Retrieving Appointments');
  let callCenterNumber = $('.trans_id').first().text() || '888-651-9950';
  // console.log(callingelement)
  // If this is the first time going through this function,
  // define a flag so we know if it's been repeated
  if (typeof global.ccUserInfo.attemptsAtAppointmentAjax === 'undefined') {
    global.ccUserInfo.attemptsAtAppointmentAjax = 0;
  }

  // Step up the variable
  global.ccUserInfo.attemptsAtAppointmentAjax++;

  let leadData = getLeadData();

  leadData.unfilteredAppointments = JSON.stringify(global.unfilteredAppointments);

  leadData.action = "cc_retrieve_appointments";
  /* eslint-disable */
  let shouldContinue;
  /* eslint-enable */

  var leadSegment,leadValue;

  if (global.ccUserInfo.attemptsAtAppointmentAjax === 1) {
    $.ajax({
      type: 'POST',
      url: ajaxurl,
      dataType: 'json',
      contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
      data: leadData,
      cache: false,
      headers: { "cache-control": "no-cache" },
      success: function(msg) {
        if((msg.schedulingDisabled != undefined && msg.schedulingDisabled == true) || (msg.appointments != undefined && msg.appointments == "No Appointments")){
          if(msg.appointments == "No Appointments"){
            global.dataLayer.push({'event':'No Apts Loaded'});
          }
          leadValue = msg.monetaryValue;
          leadSegment = msg.leadSegment;
          global.dataLayer.push({ 'Lead Value': leadValue});
          global.dataLayer.push({ 'Lead Segment': leadSegment});
          global.ccUserInfo.leadSegment = parseFloat(msg.leadSegment);
          global.ccUserInfo.model2PilotCenter = msg.model2PilotCenter;
          global.ccUserInfo.leadScore == msg.leadScore;
          flagGYIM();

          global.dataLayer.push({ 'scoreAndSegment': global.ccUserInfo.leadScore+global.ccUserInfo.leadSegment});
          global.dataLayer.push({ 'orderNumber': createUuid()});
          global.dataLayer.push({ 'event': 'Scored and Segmented'});

          schedulingDisabledPopup();
          return true;
        }
        // if(msg.promptToCallWithSchedule){
        //   global.dataLayer.push( { 'event': 'Schedule Disabled' } );
        //   promptToCallWithScheduleDisplay();
        // }

        hideWaitingIcon();
        // console.log(msg);
        shouldContinue = true;
        let retrievedGUID;
        if (msg.size > 0) {
          if(msg.leadSegment !== undefined){
            leadValue = msg.monetaryValue;
            leadSegment = msg.leadSegment;
            global.dataLayer.push({ 'Lead Value': leadValue});
            global.dataLayer.push({ 'Lead Segment': leadSegment});
            global.ccUserInfo.leadSegment = parseFloat(msg.leadSegment);
          }

          global.ccUserInfo.model2PilotCenter = msg.model2PilotCenter;
          global.ccUserInfo.leadScore = msg.leadScore;
          flagGYIM();

          global.dataLayer.push({ 'scoreAndSegment': global.ccUserInfo.leadScore+global.ccUserInfo.leadSegment});
          global.dataLayer.push({ 'orderNumber': createUuid()});
          global.dataLayer.push({ 'event': 'Scored and Segmented'});

          processAppointments(msg.appointments);

          if( msg.Appointment_Scheduled__c == true && ! global.ccUserInfo.testing ){

            //Set the rescheduling flag
            global.ccUserInfo.reschedulingAppointment = true;

            if(msg.Can_Reschedule!== undefined && !msg.Can_Reschedule){
              //console.log(msg.Can_Reschedule);
              //console.log("test2");
              //Get the center name if it is set
              var centerName = "one of our centers";
              if (typeof(msg.Scheduled_At) !== 'undefined' && msg.Scheduled_At !== null ){
                centerName = "our<br/>" + msg.Scheduled_At + " Center";
              }

              $('#webform-component-messages .message-text').html("It looks like you're already scheduled at " + centerName + "<br/><br/> For questions or assistance, please call us at:<br/><strong>" + callCenterNumber + ".</strong>");
              showMessageBox();
              // $(callingelement).slideDown();

              shouldContinue = false;

              global.dataLayer.push({"error_data": msg});
              global.dataLayer.push({"error_message": "Lead trying to reschedule within 48 hours"});
              global.dataLayer.push({"event": "ajax-error"});
            }else{
              $('#webform-component-messages .buttons').find("> div").hide();

              $('#webform-component-messages .message-text').html("It looks like you're already scheduled at our <br/>" + msg.Scheduled_At + " Center.<br/><br/>Would you like to get an appointment reminder or reschedule that appointment?");

              const $reminderButtonHtml = $('<div class="temp">Reminder</div>').appendTo($('#webform-component-messages .buttons'));
              const $rescheduleButtonHtml = $('<div class="temp">Reschedule</div>').appendTo($('#webform-component-messages .buttons'));
              const $cancelButtonHtml = $('<a class="temp" style="font-size: 11px;">Cancel My Appointment</a>').appendTo($('#webform-component-messages .buttons'));

              const $rescheduleButton = $rescheduleButtonHtml;
              const $reminderButton = $reminderButtonHtml;
              const $cancelButton = $cancelButtonHtml;

              if(msg.IsConverted){
                leadData.AccountID = msg.ConvertedAccountId;
              }

              leadData.action = "cc_salesforce_send_confirmation_email";
              leadData.testing = window.ccUserInfo.testing;

              $reminderButton.click(function(){
                $reminderButton.text("Please wait...");
                jQuery.ajax({
                  url: ajaxurl,
                  data: leadData,
                  cache: false,
                  dataType: "json",
                  type: "POST",
                  success: function(result) {
                    if(result == "true"){
                      $('#webform-component-messages .message-text').html("You have been sent a reminder email at the email we have on file for you.");
                      $('#webform-component-messages .buttons').find(".temp").remove();
                      $('#webform-component-messages .buttons').find("> div").show();
                    }else{
                      $('#webform-component-messages .message-text').html("Sorry, something went wrong with sending your reminder email. <br /> Please call us at:<br/><strong>" + callCenterNumber + ".</strong><br/>For your appointment info.");
                      $reminderButton.text("Reminder");
                    }
                  },error: function(){
                    $('#webform-component-messages .message-text').html("Sorry, something went wrong with sending your reminder email. <br /> Please call us at:<br/><strong>" + callCenterNumber + ".</strong><br/>For your appointment info.");
                    $reminderButton.text("Reminder");
                  },
                });

                // GTM/GA Tags for Reminder
                global.dataLayer.push( { 'already_scheduled': 'reminder' } );
                global.dataLayer.push( { 'event': 'Already Scheduled' } );
              });

              $rescheduleButton.click(function(){
                hideMessageBox('#webform-component-messages');
                $('#webform-component-messages .buttons').find(".temp").remove();
                $('#webform-component-messages .buttons').find("> div").show();

                // GTM/GA Tags for Reschedule
                global.dataLayer.push( { 'already_scheduled': 'reschedule' } );
                global.dataLayer.push( { 'event': 'Already Scheduled' } );
              });

              $cancelButton.click(function(){
                $('#webform-component-messages .message-text').html("To cancel your appointment,<br/> please call us at:<br/><strong>" + callCenterNumber + ".</strong>");
                $('#webform-component-messages .buttons').find(".temp").remove();

                // GTM/GA Tags for Cancel
                global.dataLayer.push( { 'already_scheduled': 'cancel' } );
                global.dataLayer.push( { 'event': 'Already Scheduled' } );

                var $clickToCallButtonHtml = $("<a class='temp click-to-call'>Or Have Us Call You</a>").prependTo($('#webform-component-messages .buttons'));
                var $clickToCallButton = $clickToCallButtonHtml;
                $clickToCallButton.click(function(){
                  $('#webform-component-messages .buttons').find(".temp").remove();
                  $('#webform-component-messages .buttons').find("> div").show();
                  loadCallMeNowForm();
                  showCallMeNow();

                  // GTM/GA Tags for Cancel - Tried to Set up Call Me Now
                  global.dataLayer.push( { 'already_scheduled': 'call-me' } );
                  global.dataLayer.push( { 'event': 'Already Scheduled' } );
                });

                //Add the user to a "Tried To Cancel" Campaign
                var cancelData = {};
                cancelData.GUID = global.ccUserInfo.contactID || retrievedGUID;
                cancelData.isContact = ( global.ccUserInfo.contactID !== "" && global.ccUserInfo.contactID !== null ) ? true : false;
                cancelData.TransId = "onlinecancellation";
                cancelData.action = "cc_salesforce_submit_campaign_member";
                cancelData.securityToken = global.securityToken;
                cancelData.testing = window.ccUserInfo.testing;

                jQuery.ajax({
                  url: ajaxurl,
                  data: cancelData,
                  cache: false,
                  dataType: "json",
                  type: "POST",
                  // success: function( result, success ) {
                  //   //console.log( 'Success: ', result );
                  // },
                  // error: function( result, error ) {
                  //   //console.log( 'Error: ', result );
                  // }
                });
                return false;
              });

              showMessageBox();

              shouldContinue = true;
            }

          }
        } else {
          if (typeof msg[0] !== 'undefined' && typeof msg[0].success !== 'undefined' && msg[0].success === true) {

            if(msg[0].leadSegment  !== undefined){
              leadValue = msg[0].monetaryValue;
              leadSegment = msg[0].leadSegment;
              global.dataLayer.push({ 'Lead Value': leadValue});
              global.dataLayer.push({ 'Lead Segment': leadSegment});
              global.ccUserInfo.leadSegment = parseFloat(msg[0].leadSegment);
            }

            global.ccUserInfo.leadScore = msg[0].leadScore;
            flagGYIM();

            global.dataLayer.push({ 'scoreAndSegment': global.ccUserInfo.leadScore+global.ccUserInfo.leadSegment});
            global.dataLayer.push({ 'orderNumber': createUuid()});
            global.dataLayer.push({ 'event': 'Scored and Segmented'});
          
            processAppointments(msg[0].appointments);


            global.ccUserInfo.esi = msg.esi;
            global.ccUserInfo.nTile = msg.nTile;
            global.ccUserInfo.consultPotential = msg.consultPotential;
          } else {
            var flag = false;

            if (typeof msg[0] !== 'undefined' &&
              (typeof msg[0].errors !== 'undefined') &&
              (typeof msg[0].errors[0] !== 'undefined') &&
              (typeof msg[0].errors[0].statusCode !== 'undefined') &&
              (msg[0].errors[0].statusCode === 'INVALID_EMAIL_ADDRESS')
            ) {
              $('#webform-messages').html('<div class="alert alert-danger" role="alert">That doesn\'t seem to be a valid email address. Please correct, or call ' + callCenterNumber + ' for assistance.</div>');
              flag = true;
            } else if (global.ccUserInfo.attemptsAtAppointmentAjax < 2) {
              retrieveAppointments('');
            } else {
              $('#webform-messages').html('<div class="alert alert-danger" role="alert">There was an issue starting the scheduling process. Please try again later, or call ' + callCenterNumber + ' for assistance.</div>');
              flag = true;
            }

            if (flag) {
              global.dataLayer.push({ "error_data": msg });
              global.dataLayer.push({ "error_message": "Server Error looking up/ creating lead info" });
              global.dataLayer.push({ "event": "ajax-error" });
              shouldContinue = false;
            }
          }
        }
        if(msg == 'no auth token'){
          $('#webform-messages').html('<div class="alert alert-danger" role="alert">There was an issue connecting to our servers. Please try again later, or call ' + callCenterNumber + ' for assistance.</div>');
        }
      },
      error: function(result) {
        // If the ajax fails for some reason, try again
        if (global.ccUserInfo.attemptsAtAppointmentAjax < 2) {
          retrieveAppointments('');
        } else {
          hideWaitingIcon();
          $('#webform-messages').html('<div class="alert alert-danger" role="alert">There was an issue connecting to our servers. Please try again later, or call ' + callCenterNumber + ' for assistance.</div>');

          global.dataLayer.push({ "error_data": result });
          global.dataLayer.push({ "error_message": "Ajax Error looking up/ creating lead info" });
          global.dataLayer.push({ "event": "ajax-error" });
        }
      },
    });
  }
}

/**
 * Data that is sent out during step 2/3 of OS form
 */
export function getLeadData() {

  let leadData = {};

  if (global.ccUserInfo.guid !== '') {
    leadData.GUID = global.ccUserInfo.guid;
  }

  // set global Google Analytics information to append to leadData below
  setGlobalGAInfo();

  leadData.FirstName = global.ccUserInfo.firstName.trim();
  leadData.LastName = global.ccUserInfo.lastName.trim();
  leadData.Phone = global.ccUserInfo.phone;
  leadData.Email = global.ccUserInfo.email.trim();
  leadData.Address = global.ccUserInfo.address.trim();
  leadData.City = global.ccUserInfo.city.trim();
  leadData.State = global.ccUserInfo.state;
  leadData.Zip = global.ccUserInfo.zipcode;
  leadData.MarketingSource = 'CCWebsiteOSI';
  leadData.testing = global.ccUserInfo.testing;
  leadData.FewQuestions = global.ccUserInfo.fewQuestions;
    leadData.GACLIENTID = global.ga.clientId;
    leadData.GAUSERID = global.ga.userId;
    leadData.GATRACKID = global.ga.trackingId;
    leadData.GCLID = global.ga.gclId;
  

  leadData.GUID = global.ccUserInfo.guid;
  leadData.accountId = global.ccUserInfo.accountId;
  leadData.contactId = global.ccUserInfo.contactId;

  leadData.appointmentScheduled = global.ccUserInfo.appointmentScheduled;
  leadData.consultInventory = global.ccUserInfo.consultInventory;
  leadData.securityToken = global.securityToken;
  leadData.leadScore = global.ccUserInfo.leadScore;

  var parsed = queryString.parse(location.search);

  if(parsed.testLeadScore !== undefined){
    leadData.leadScore = parsed.testLeadScore;
  }

  if(parsed.testLeadScore !== undefined){
    leadData.testLeadScore = parsed.testLeadScore;
  }

  if(parsed.model2testing !== undefined){
    leadData.model2testing = true;
  }

  var testLeadSegment = parsed.testLeadSegment;
  if(testLeadSegment !== undefined){
    leadData.testLeadSegment = testLeadSegment;
  }

  var duplicateTest = parsed.duplicateTest;
  if(duplicateTest !== undefined){
    leadData.duplicateTest = true;
  }

  if (global.ccUserInfo.centerID !== '') {
    leadData.CenterId = global.ccUserInfo.centerID;
  }

  if(global.ccUserInfo.distanceToCenter != ''){
    leadData.distanceToCenter = global.ccUserInfo.distanceToCenter;
  }

  if (typeof global.ccUserInfo.availableCenters[global.ccUserInfo.centerID] !== 'undefined') {
    leadData.CenterTimeZone = global.ccUserInfo.availableCenters[global.ccUserInfo.centerID].timezone;
    leadData.CenterState = global.ccUserInfo.availableCenters[global.ccUserInfo.centerID].state;
  }

  // check for ADI page
  if ($("body").hasClass("adi-schedule")) {
    leadData.page = 'adi';
    leadData.referral_id = global.ccUserInfo.referral_id;
  } else {
    leadData.page = 'os';
  }


  leadData.trans_id = global.ccUserInfo.trans_id;

  if(global.ccUserInfo.retryCall){
    leadData.isRetry = true;
    if(global.ccUserInfo.retryId != undefined){
      leadData.retryId = global.ccUserInfo.retryId;
    }
  }

  return leadData;
}
