import {setUserParams} from '../global/setUserParams';
import {setUserParamsLegacy} from '../global/setUserParamsLegacy';
import {responsiveTableStack} from '../global/tables';
import {Site as w1_site} from '../global/w1-only';
import {ccMenu as w1_ccmenu} from '../global/w1-menu';
import Cookie from 'js-cookie';
import {is_w1, is_w2} from '../global/functions';
import {setOathToken} from '../form/salesforceOath';
import {saveCampaign,showMessageBox,generateUserFrontEndId} from '../form/formFunctions'

import List from 'list.js';
import 'datatables.net';
import 'datatables.net-bs4';
import he from 'he';
import 'jquery-expander/jquery.expander';
import moment from 'moment';
// import {sendFacebookEvent} from '../global/facebookApiCalls';

// todo: add w1 only JS
 
export default {
  init() {
    $.ajaxSetup({ cache: false });
    // if w1
    if(is_w1()) {
      w1_site.init();
      w1_ccmenu.init();
    }
    
    //exclude the popup from schedule and waiting room pages 
    if(!window.location.pathname.includes("virtual-waiting-room") && !window.location.pathname.includes("schedule-consultation") && $('.dont-show-popup').length == 0){
      checkIfShowingClosedownMessage();
    }

    generateUserFrontEndId();
    
    // JavaScript to be fired on all pages
    const mobileMenuBreakpoint = 1075;

    setUserParamsLegacy();
    setUserParams();

    // responsively stacks tables
    responsiveTableStack('table.table-stack-vertical');

    // w2 - scroll down, adds 'scrolled' class
    if( $('.btn-schedule-wrap').length > 0 ) {
      // Add class for nav UX
      const header = $('.btn-schedule-wrap').offset().top;

      $(window).on('scroll', function () {
        if ($(window).scrollTop() > header) {
          $('.banner').addClass('scrolled');
          $('body').addClass('scrolled');
        } else {
          $('.banner').removeClass('scrolled');
          $('body').removeClass('scrolled');
        }
      });
    }

    if(!navigator.userAgent.includes("Chrome") && !navigator.userAgent.includes("Firefox") && !navigator.userAgent.includes("Edg")){
      document.body.classList.add("is-safari");
    }

    if(!navigator.userAgent.includes("Chrome") && !navigator.userAgent.includes("Firefox") && !navigator.userAgent.includes("Edg") && navigator.userAgent.toLowerCase().includes("iphone")){
      document.body.classList.add("is-iphone");
    }

    if(!navigator.userAgent.includes("Chrome") && navigator.userAgent.toLowerCase().includes("firefox") && !navigator.userAgent.includes("Edg")){
      document.body.classList.add("is-foxy");
    }

    //fix doctor rows on smaller loaded devices, not resizing 
    if ($(window).width() < 770) {
      $('.doctors-location-page-row').replaceWith(function() {
    return $('.doctorRow', this);
    });
    }

    //push ga tracking for Online VC to salesforce
    $(".online-appt-campaign").click(function(e){
      global.dataLayer.push({ 'event': 'virtual consult clicked' });
    });


    //logic for bootsrap columns on online-appointments page
    $(window).on("resize", function () {
      if (screen.width < 784) {
        $('.left-vc-col').removeClass('col-md-7');
        $('.right-vc-col').removeClass('col-md-5');
        $('.left-vc-col').addClass('col-md-6');
        $('.right-vc-col').addClass('col-md-6');
      }else{
        $('.left-vc-col').removeClass('col-md-6');
        $('.right-vc-col').removeClass('col-md-6');
        $('.left-vc-col').addClass('col-md-7');
        $('.right-vc-col').addClass('col-md-5');
      }
    // Invoke the resize event immediately
    }).resize();

    //logic for sitewide popup
    $(window).on("resize", function () {
      if (screen.width < 784 || window.innerWidth < 784) {
        $('.popup-left').addClass(' rounded mx-auto d-block');
      } else {
        $('.popup-left').removeClass(' rounded mx-auto d-block');
      }
    }).resize();
  
   

    // Open all external links in a new window
    $('a').filter('[href^="http"], [href^="//"]').not('[href*="' + window.location.host + '"]').attr('rel', 'noopener noreferrer').attr('target', '_blank');

    // GDPR: Set Cookie & hide banner if user clicks on "close (x)" button
    $('#close-gdpr').click(function() {
      Cookie.set('cc-terms-accepted', true);
      $('#gdpr-banner-wrapper').hide();
    });

    // Location
    // let center = Cookie.get('cc2-center-detected');
    // if (!center) {
    //   $.getScript('https://js.maxmind.com/js/apis/geoip2/v2.1/geoip2.js', function() {
    //     // @todo: this breaks for firefox - cant detect location
    //     geoip2.city(function(position) {
    //       position.coords = [];
    //       position.coords.latitude = position.location.latitude
    //       position.coords.longitude = position.location.longitude
    //       foundLocation(position);
    //     });
    //     // @todo: delete GDPR stuff
    //     geoip2.country(function(location) {
    //       if (location.continent.code == 'EU') {
    //         // GDPR: We have an EU user, check for terms acceptance
    //         var hasAcceptedTerms = Cookie.get('cc-terms-accepted');
    //         if (!hasAcceptedTerms || typeof hasAcceptedTerms == 'undefined' ){
    //           // GDPR: Terms and conditions not accepted yet, show the GDPR banner
    //           $('#gdpr-banner-wrapper').removeClass('d-none');
    //         } 
    //       } 
    //     });
    //   });
    // } else {
    //   center = window.atob(center);
    //   personalizeLocation(center);
    // }

    function personalizeLocation(data) {
      if (Cookie.get('cc2-center-defined')) {
        data = $.parseJSON(window.atob(Cookie.get('cc2-center-defined')));
      } else {
        data = $.parseJSON(window.atob(Cookie.get('cc2-center-detected')));
      }


      /*
       * adds the 'Explore the {city} Center' link to the menu nav
       */

      var cityName = data.city;

      // removes the word "The " from city names (desktop)
      if( cityName.toLowerCase().startsWith('the ') )
        cityName = cityName.substring(4);

      // mobile
      $('.banner .center-name').html('<a href="'+ data.link +'">Explore '+ data.city +' Center</a> <span>|</span>' );
      // desktop
      $('.banner-bottom .center-name').html('<a href="'+ data.link +'">Explore the '+ cityName +' Center</a> <span>|</span>' );



      // Update "your clearchoice center" card
      $('.card.icon-dental-implants-your-clearchoice').prop('href', data.link);
      $('a[data-target="#modal-change-location"]').html('change');

      if ($('.local-card').length || $('.local-center-description').length || $('.local-doctors').length || window.innerWidth < mobileMenuBreakpoint) {
        $.ajax({
          url: '/wp-json/clearchoice/location/?id='+data.id,
          success: function(data) {
            $('.location-content').html(he.decode(data.content));
            $('.location-name').html(data.title);
            $('.location-address').html(data.addr);
            $('.location-address2').html(data.addr2);
            $('.location-city').html(data.city);
            $('.location-state').html(data.state);
            $('.location-zip').html(data.zip);
            $('.location-email').attr('href', 'mailto:'+data.email);
            $('.location-email').html(data.email);
            $('.location-link').attr('href', data.link);
            $('.location-tel').html(data.tel);
            $('.location-fax').html(data.fax);
            $('.location-legal').html(data.legal);
            $('.location-emergency').html(data.emergency);
            $('.location-directions').attr('href', 'https://maps.google.com/?q='+data.addr+' '+data.addr2+', '+ data.state + ' '+data.zip);

            const doctorFirst = data.doctors[Object.keys(data.doctors)[0]];
            if (typeof doctorFirst !== "undefined") {
              if (doctorFirst.img) {
                $('.dr-1-image').html('<img src="'+doctorFirst.img+'">');
              } else {
                $('.dr-1-image').html('<img src="/wp-content/themes/clearchoice/dist/images/Photo_Coming_Soon_80_sq_7bf86472.jpg">');
              }
              $('.dr-1-first-name').html(doctorFirst.first_name);
              $('.dr-1-last-name').html(doctorFirst.last_name);
              $('.dr-1-practice').html(doctorFirst.practice);
              $('.dr-1-content').html(he.decode(doctorFirst.content));
              $('.dr-1-link').attr('href', doctorFirst.link);
            }

            const doctorSecond = data.doctors[Object.keys(data.doctors)[1]];
            if (typeof doctorSecond !== "undefined") {
              if (doctorSecond.img) {
                $('.dr-2-image').html('<img src="'+doctorSecond.img+'">');
              } else {
                $('.dr-2-image').html('<img src="/wp-content/themes/clearchoice/dist/images/Photo_Coming_Soon_80_sq_7bf86472.jpg">');
              }
              $('.dr-2-first-name').html(doctorSecond.first_name);
              $('.dr-2-last-name').html(doctorSecond.last_name);
              $('.dr-2-practice').html(doctorSecond.practice);
              $('.dr-2-content').html(he.decode(doctorSecond.content));
              $('.dr-2-link').attr('href', doctorSecond.link);
            } else {
              $('.local-doctors .local-dr-2').addClass('d-none');
            }

            $('.local-card').removeClass('d-none');
            $('.local-center-description').removeClass('d-none');
            $('.local-doctors').removeClass('d-none');
          },
        });
      }
    }

    function foundLocation(position) {
      const locationLat = position.coords.latitude;
      const locationLng = position.coords.longitude;

      $.ajax({
        url: '/wp-json/clearchoice/locations/?lat='+locationLat+'&lng='+locationLng,
        success: function(data) {
          let closest = data.closest;
          data = data[closest];
          Cookie.set('cc2-center-detected', window.btoa(JSON.stringify(data)));
          personalizeLocation(data);
        },
      });
    }


    /**
     * Mobile menu stuff
     */
    const mobileCityCenter = $('.banner-top .nav-utility');

    // Open Menu
    $('.banner-nav-wrap').on('show.bs.collapse', function () {
      $('.banner button[data-toggle="collapse"] b').text('Close');
      $('.banner button[data-toggle="collapse"] i').removeClass('fa-bars').addClass('fa-times')
      $('body').addClass('menu-open');
      $('<div class="modal-backdrop modal-backdrop-menu fade show"></div>').appendTo('body');

      $('.banner .menu-item.active').removeClass('active');

      $(document).keyup(function(e) {
        if (e.keyCode === 27) {
          $('.banner-nav-wrap').collapse('hide');
        }
      });

      $(document).on('click touchstart', function(e) {
        if (!$(e.target).closest('nav').length) {
          $('.banner-nav-wrap').collapse('hide');
        }
      });

      //$('.banner .btn-schedule-wrap').hide();
      mobileCityCenter.addClass('hide');
    });


    // Close Menu
    $('.banner-nav-wrap').on('hide.bs.collapse', function () {
      $('.banner button[data-toggle="collapse"] b').text('Menu');
      $('.banner button[data-toggle="collapse"] i').removeClass('fa-times').addClass('fa-bars');
      $('body').removeClass('menu-open');
      $('.modal-backdrop').remove();
      //$('.banner .btn-schedule-wrap').show();
        mobileCityCenter.removeClass('hide');
    });

    $('.banner-nav-wrap').on('shown.bs.collapse', function () {
      $('#mobile-phone a').toggleClass('disabled');
    });

    $('.banner-nav-wrap').on('hidden.bs.collapse', function () {
      $('#mobile-phone a').toggleClass('disabled');
    });

    $('#mobile-phone a').on('click', function(e) {
      if ($(this).hasClass('disabled')) {
        e.preventDefault();
      } else {
        location.href = $(this).attr('href');
      }
    });


    $('.banner-nav-wrap .nav-primary > .nav > .menu-item-has-children > a').addClass('menu-toggle-alt');
    const menuTrigger = $('.nav-primary .menu-toggle-alt, .nav-primary .menu-item-has-children li .menu-toggle');
    menuTrigger.click(function(e) {
      if (window.innerWidth < mobileMenuBreakpoint) {
        e.preventDefault();
        $(this).closest('li').toggleClass('active').siblings('.active').removeClass('active');
      }
    });

    // Videos
    window.wistiaInit = function(W) {
      $('.cc-video').one('click', function(e) {
        e.preventDefault();
        $(this).find('.cc-video-placeholder').hide();
        $(this).find('.cc-video-html').show();

        const wistiaEmbed = $(this).find('.wistia_embed').attr('id');
        W.api(wistiaEmbed).play();
      });
    };

    // Change location
    $('#modal-change-location').on('shown.bs.modal', function() {
      $('#locate-center-address').focus();
    });

    // Make sure only a valid address from Google is submitted
    function validateAddress(input) {
      if ($('.pac-container').is(':hover') === false) {
        if($('.pac-item.pac-item-selected').is(':visible')){
          google.maps.event.trigger(input, 'keydown', { keyCode: 13 });
        }else{
          google.maps.event.trigger(input, 'keydown', { keyCode: 40 });
          google.maps.event.trigger(input, 'keydown', { keyCode: 13 });
        }
      }
    }
    if(!$(".closed-page").length > 0){
      let place;
      let placeEnterPressed = false;
      const autocompleteInput = document.getElementById('locate-center-address');
      const autocomplete = new google.maps.places.Autocomplete(autocompleteInput, {
        types: ['(regions)'],
        componentRestrictions: {country: 'us'},
      });
      autocomplete.addListener('place_changed', function() {
        if (autocomplete.getPlace() !== undefined) {
          place = autocomplete.getPlace();
        }

        if (placeEnterPressed) {
          $('#modal-change-location .locate-center').submit();
        }
      });

      // Select first address on focusout
      $('#modal-change-location').on('focusout', '#locate-center-address', function() {
        validateAddress(this);
      });

      // Select first address on enter in input
      $('#modal-change-location').on('keydown', '#locate-center-address', function(e) {
        if (e.keyCode == 13) {
          validateAddress(this);
          placeEnterPressed = true;
        }
      });

      // A new center has been selected from the modal window
      $('#modal-change-location .locate-center').submit(function(e) {
        e.preventDefault();
        if (place === undefined || !place.formatted_address) {
          return;
        }

        // Gets the closest centers by lat & lon
        $.ajax({
          url: '/wp-json/clearchoice/locations/?lat='+place.geometry.location.lat()+'&lng='+place.geometry.location.lng(),
          success: function(data) {
            $('.locate-center-form').addClass('d-none');
            $('.locate-center > .row .text-muted').addClass('d-none');
            $('.locate-center-results').removeClass('d-none');
            $('.locate-center-results .locate-center-input').html(place.formatted_address);

            $('.locate-center-results .list-group').empty();

            let locations = [];

            $.each(data, function(index, obj) {
              if (typeof(obj.title) !== 'undefined') {
                if (obj.title !== 'Default Location') {
                  $('.locate-center-results .list-group').append('<button type="button" class="list-group-item list-group-item-action" data-center-id="' + index + '">ClearChoice Dental Implant Center in ' + obj.title + ' <spav class="distance small">(' + parseFloat(Math.round(obj.distance * 100) / 100).toFixed(1) + ' miles)</span></button>');
                  locations.push([obj.title, obj.lat, obj.lng]);
                }
              }
            });

            const locationClosest = $('.locate-center-results').find('button[data-center-id="'+data.closest+'"]');
            $('.locate-center-results .list-group').prepend(locationClosest);

            const map = new google.maps.Map(document.getElementById('map'), {
              zoom: 3,
              mapTypeId: google.maps.MapTypeId.ROADMAP,
            });

            let bounds = new google.maps.LatLngBounds();
            let infowindow = new google.maps.InfoWindow();
            let marker, i;

            // Add a click listener to each location on the map
            for (i = 0; i < locations.length; i++) {
              marker = new google.maps.Marker({
                position: new google.maps.LatLng(locations[i][1], locations[i][2]),
                map: map,
              });

              bounds.extend(marker.position);

              google.maps.event.addListener(marker, 'click', (function(marker, i) {
                return function() {
                  infowindow.setContent(locations[i][0]);
                  infowindow.open(map, marker);
                }
              })(marker, i));
            }

            map.fitBounds(bounds);

            $('.locate-center-results .list-group button').each(function() {
              $(this).click(function() {
                data.defined = $(this).data('center-id');

                let dataDefined = data[data.defined];

                $('.locate-center-results .list-group button').removeClass('active');
                $(this).addClass('active');

                Cookie.set('cc2-center-defined', window.btoa(JSON.stringify(dataDefined)));

                personalizeLocation(dataDefined);

                window.location.replace(dataDefined.link);

                $('#modal-change-location').modal('hide');
              });
            });
          },
        });
      });

      $('a[href="#locate-center-form"]').click(function(e) {
        e.preventDefault();

        $('.locate-center-results').addClass('d-none');
        $('.locate-center-results .list-group').empty();
        $('.locate-center-form').removeClass('d-none');
        $('.locate-center > .row .text-muted').removeClass('d-none');
        $('.locate-center-form input[type="text"]').focus();
      });

      $('.locate-center-results .list-group button').each(function() {
        $(this).click(function() {
          $('.locate-center-results .list-group button').removeClass('active');
          $(this).addClass('active');
        });
      });

    }

    const headerHeight = $('.banner').height();
    $('a[href="#top"]').click(function() {
      $('html, body').animate({
        scrollTop: $('body').offset().top - headerHeight,
      }, 250);
    });

    $(document).on('facetwp-loaded', function() {
      $('html, body').animate({
        scrollTop: $('.main').offset().top - headerHeight,
      }, 500);
    });
    

    // TODO: move to a new router for reviews/location single
    const reviewList = new List('review-list', {
      valueNames: [],
      page: 5,
      pagination: true,
    });

    $('table[data-table]').DataTable({
      searching: false,
      paging: false,
      info: false,
    });

    // Truncation
    $('.read-more').expander({
      slicePoint: 420,
      expandText: 'Read more',
      userCollapseText: 'Read less',
    });

    reviewList.on('updated', function() {
      $('.read-more').expander({
        slicePoint: 420,
        expandText: 'Read more',
        userCollapseText: 'Read less',
      });
    });


    // Slider for patient/smile healthy stories
    $('.owl-carousel').owlCarousel({
      loop:true,
      checkVisible: false,
      dots: false,
      margin: 10,
      nav: true,
      responsiveRefreshRate: 100,
      navText: [],
      responsive: {
        0: {
          items: 1,
        },
        576: {
          items: 2,
        },
        768: {
          items: 3,
        },
      },
    });


    /**
     * Privacy Policy banner
     */

     //check if privacy policy banner should be displayed
     const privacyBannerHtml = '<nav class="navbar fixed-bottom banner__privacy-policy tiny-text">\
  <p>The site uses essential cookies to function.  It also uses non-essential cookies for marketing and advertising.  For more information please see our\
   <a href="/cookie-policy">Cookie Policy</a>\
  and <a class="text-underline" href="/privacy-policy/">Privacy Policy</a>. To opt out of certain website tracking and targeted advertising please visit the\
  <a href="http://optout.aboutads.info/?c=2&lang=EN">Digital Advertising Alliance</a> or <a href="http://optout.networkadvertising.org/?c=1">Network Advertising Initiative</a>.</p>\
  <button class="close float-right text-white">X</button>\
</nav>'

    const cookiePrivacyBanner = 'cc-privacy-accepted';
    var privacyPolicyLastUpdatedDateString = $(".privacy-banner").data("last-updated");
    var privacyCookieDateString = Cookie.get(cookiePrivacyBanner);

    if(privacyCookieDateString == undefined || privacyCookieDateString.length == 0){
      $(".privacy-banner").html(privacyBannerHtml);
    } else {
      var privacyPolicyLastUpdatedDate = moment(privacyPolicyLastUpdatedDateString).format('YYYY-MM-DD HH:mm:ss');
      var privacyCookieDate = moment(privacyCookieDateString).format('YYYY-MM-DD HH:mm:ss');
      if(privacyPolicyLastUpdatedDate > privacyCookieDate){
        $(".privacy-banner").html(privacyBannerHtml);
      }
    }
    
    const containerPrivacyBanner = $('.banner__privacy-policy');
    if(containerPrivacyBanner.length > 0){
      // click close button
      containerPrivacyBanner.find('.close').click(function(e){
        e.preventDefault();
        setPrivacyCookie();
      });

      // on page scroll, hide
      window.onscroll = function() {
          setPrivacyCookie();
      };

      // click on anything, hide
      window.onclick = function() {
          setPrivacyCookie();
      };
    }
    /*
    // checks the Privacy cookie, to see if they accepted
    function checkPrivacyCookie() {
        return Cookie.get(cookiePrivacyBanner);
    }
    */

    // sets the cookie
    function setPrivacyCookie() {
      let d = new Date();
      let date = moment(d).format('YYYY-MM-DD HH:mm:ss');

      // hide Privacy banner
      containerPrivacyBanner.fadeOut();
      Cookie.set(cookiePrivacyBanner, date);

      // test a date in the past to test
      //Cookie.set(cookiePrivacyBanner, '9/13/2001, 2:07:06 PM');
    }

    
    var elems = document.getElementsByClassName('collapse-control');
    for(var i=0;i<elems.length;i++){
      elems[i].addEventListener("click", function(){
        this.classList.toggle('collapsed');
        this.classList.toggle('open');
        var elem = this.nextElementSibling;
        elem.classList.toggle('mobile-collapse');
      });
    }

    /**
     * Google Analytics
     */

    // @todo: could possibly move this to/check via php
    // functions for storing `gclid` from the url query string
    function getParam(p){
      var match = RegExp('[?&]' + p + '=([^&]*)').exec(window.location.search);
      return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
    }

    var gclid = getParam('gclid');

    if(gclid){
      var gclsrc = getParam('gclsrc');
      if(!gclsrc || gclsrc.indexOf('aw') !== -1)
          Cookie.set('cc-gclid', gclid, { expires: 90 })
    }

    $( document ).ready(function(){
      if(window.location.pathname == "/online-appointments/"){ 
        console.log('reached');
        $( ".centered-virtual-tour" ).append( "<div class='iframe-container'><iframe width='853' height='480' id='virtual-tour-frame' src='https://my.matterport.com/show/?m=BU3ZKQQMVyF&brand=0' frameborder='0' allowfullscreen allow='xr-spatial-tracking'></iframe><div>" );
        $( ".loading-label" ).hide();
      }
      if(global.fbPageViewSent == undefined && typeof global.fbq !== 'undefined'){
        global.fbPageViewSent = true;
        // sendFacebookEvent('PageView');
      }else{
        console.log("fail");
      }
      // global.settingsClicked = false;

      // function closeConsentBoxOnTimeout(waiting){
      //   setTimeout(function(){
      //     // if(!$('#onetrust-pc-sdk').is(":visible")){
      //     //   global.settingsClicked = false;
      //     // }
      //     if(!global.settingsClicked){
      //       document.elementFromPoint(0,0).click();
      //     }else{
      //       closeConsentBoxOnTimeout();
      //     }
      //   },3000);
      // }

      // $('.cookie-setting-link').click(function(){
      //   global.settingsClicked = true;
      // });
      setTimeout(function(){
        $('.cookie-setting-link').click(function(){
          setTimeout(function(){
              $('.ot-switch').click(function() {
                  $('.main-content').animate({ scrollTop: $(document).height() }, 100);
              });
          },1100);
        });
      },1100);
      // closeConsentBoxOnTimeout();
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    /* Let GA stay on per Catherine/Legal 
    function checkForDisable(){
      var gaDisabled = (Cookie.get('gaDisabled') == 'false' || Cookie.get('gaDisabled') == undefined) ? false : true;
      if(gaDisabled){
         window['ga-disable-UA-17734015-1'] = true;
      }
    }
    */

    //checkForDisable();
    var rdpEnabled = Cookie.get('rdpEnabled');
    if(rdpEnabled == true){
      document.cookie = "_fbp=; expires=Thu, 01 Jan 1970 00:00:00 UTC;Domain=.kinsta.cloud; path=/;";
      document.cookie = "_fbp=; expires=Thu, 01 Jan 1970 00:00:00 UTC;Domain=.clearchoice.com; path=/;";
    }


    function waitForConsentChanged(t) {
       window.addEventListener("consent.onetrust", t);
    }

    function whenConsentChanges(){
      // if(global.OptanonActiveGroups != ",,"){
      //   if(!global.OptanonActiveGroups.includes(",2,")){
      //     global.dataLayer.push({ 'event': 'Performance Cookie Opt Out' });
      //   }
      //   if(!global.OptanonActiveGroups.includes(",3,")){
      //     global.dataLayer.push({ 'event': 'Functional Cookie Opt Out' });
      //   }
      //   if(!global.OptanonActiveGroups.includes(",4,")){
      //     global.dataLayer.push({ 'event': 'Targeting Cookie Opt Out' });
      //   }
      // }

      /* Let GA stay on per Catherine/Legal 
      if(!global.OptanonActiveGroups.includes(",2,") && global.OptanonActiveGroups != ",,"){
        window['ga-disable-UA-17734015-1'] = true;
        Cookie.set('gaDisabled',true);
      }else{
        Cookie.set('gaDisabled',false);
      }
      */

      if(!global.OptanonActiveGroups.includes(",4,") && global.OptanonActiveGroups != ",,"){
        var rdpEnabled = (Cookie.get('rdpEnabled') == 'false' || Cookie.get('rdpEnabled') == undefined) ? false : true;
      
        document.cookie = "_fbp=; expires=Thu, 01 Jan 1970 00:00:00 UTC;Domain=.kinsta.cloud; path=/;";
        document.cookie = "_fbp=; expires=Thu, 01 Jan 1970 00:00:00 UTC;Domain=.clearchoice.com; path=/;";
        
        //Cookie.remove('_fpb', { path: ''});

        if(rdpEnabled == false){
          global.dataLayer.push({ 'event': 'Targeting Cookie Opt Out' });
          Cookie.set('rdpEnabled',true);
          window.location.reload(true);
        } 
      } else{
        Cookie.set('rdpEnabled',false);
        //facebook pageview event
        if(global.fbPageViewSent == undefined && typeof global.fbq !== 'undefined'){
          global.fbPageViewSent = true;
          // sendFacebookEvent('PageView');
        }else{
          console.log("fail2");
        }
      }
    }

    waitForConsentChanged(whenConsentChanges);
   
  },
};

function checkIfShowingClosedownMessage(){
  var messageShown = Cookie.get('lockdownMessageAlreadyShown');
  if(messageShown != "true"){
    Cookie.set('lockdownMessageAlreadyShown',"true");
    //init message box
    initializeSiteWidePopup("lockDownPopup");
    
    var popupHtml = '  <div class="row"><div class="lockDownMainText col-md-4">\
    <img class="popup-left" src="https://www.clearchoice.com/wp-content/uploads/2020/12/CC_Open_Icon.png">\
    </div>\
    <div id="main-text" class="lockDownMainText col-md-8"><p id="popup-large-text" class="popup-l-text">We\'re open<br>and here to help.</p><span id="popup-s-text">Operating with heightened safety.</span><br>\
    <a class="lock-Down-Cta" href="/schedule-consultation/">SCHEDULE FREE CONSULTATION</a>\
    </div></div>';

    setTextSiteWidePopup(popupHtml);
    showSiteWidePopup();
  }
}

function initializeSiteWidePopup(customClass = ""){
  var messageBoxHtml = '<div id="site-wide-popup">\
        <div class="message-container '+customClass+'">\
          <div class="exit-x-container">\
            <button type="button" class="close siteWidePopupClose" aria-label="Close">\
              <span aria-hidden="true">&times;</span>\
            </button>\
          </div>\
          <div class="message-text">\
          </div>\
        </div>\
      </div>';
  $("body").append(messageBoxHtml);
  $('.siteWidePopupClose').click(function(){
      hideSiteWidePopup();
  });
}

function setTextSiteWidePopup(htmlForPopup){
  $('#site-wide-popup .message-text').html(htmlForPopup);
}

function showSiteWidePopup(){
  var $messageBox = $('#site-wide-popup');
  $('#site-wide-popup .call-me-now-box').css('display', 'none');
  $('#site-wide-popup .message-container').css('display', 'block');
  $messageBox.addClass('open').fadeIn();
}

/**
   * Hides message box for form completion errors
   *
   * @return null
   */
function hideSiteWidePopup(id = '' ) {
  var $messageBox = $( '#site-wide-popup' );
  $messageBox.removeClass( 'open' ).fadeOut();
}

