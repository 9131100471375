import {sendFacebookEvent} from '../global/facebookApiCalls';

export function flagGYIM() {
  var threeFourFive = [3,4,5];
  var allSegments = [0,1,2,2.1,3,4,5];

  var isIm = false,
    centers = global.ccUserInfo.availableCenters,
    distanceFromSelectedCenter = centers[ global.ccUserInfo.centerID ].distance;

  if ( distanceFromSelectedCenter <= 50 ) {
    isIm = true;
  }

  if (typeof global.ccUserInfo.leadScore !== 'undefined') {
    global.dataLayer.push({ 'lead_score': global.ccUserInfo.leadScore });
    // __insp.push( [ 'tagSession', { lead_score: result.score } ] );
  }

  sendFacebookEvent('Lead');
  // Push to data layer different market values (Google Tag Manager has correlating Tags/Events/Triggers/Variables/etc.)
  global.dataLayer.push({ 'in_market': isIm });
  global.dataLayer.push({ 'out_market': !isIm });
  global.dataLayer.push({ 'leadcolor': global.ccUserInfo.leadScore });

  if ( global.ccUserInfo.leadScore === 'Yellow' || global.ccUserInfo.leadScore === 'Green' ) {
    global.ccUserInfo.isGreenYellow = true;
    if(allSegments.includes(global.ccUserInfo.leadSegment)){
      global.dataLayer.push({ 'event': 'GY05' });
      sendFacebookEvent('nonptc');
    }
    if( isIm ) {
      global.dataLayer.push({ 'is_gyim': 'true' });
      global.dataLayer.push({ 'event': 'GYIM Received' });
      global.ccUserInfo.marketScore = 'GYIM';
      sendFacebookEvent('GYIM');
    } else {
      global.dataLayer.push({ 'is_gyoom': 'true' });
      global.dataLayer.push({ 'event': 'GYOOM Received' });
      global.ccUserInfo.marketScore = 'GYOOM';
    }
  } else {
    if(threeFourFive.includes(global.ccUserInfo.leadSegment)){
      global.dataLayer.push({ 'event': 'RB345' });
      sendFacebookEvent('nonptc');
    }
    if( isIm ) {
      global.dataLayer.push({ 'is_rbim': 'true' });
      global.dataLayer.push({ 'event': 'RBIM Received' });
      global.ccUserInfo.marketScore = 'RBIM';
    } else {
      global.dataLayer.push({ 'is_rboom': 'true' });
      global.dataLayer.push({ 'event': 'RBOOM Received' });
      global.ccUserInfo.marketScore = 'RBOOM';
    }
  }
}

/**
 @todo: add ajax call here to update the fake green ADI lead records back to their original color
 */